import { useRef, useCallback, useState, ReactNode } from 'react';
import { RadarAxis } from '../RadarAxis';
import { RadarChartCategory, RadarChartContext, RadarChartContextType, RadarChartValue } from './';

export type RadarChartProps = {
  className?: string;
  useCanvas?: boolean;
  chartClassName?: string;
  categories: RadarChartCategory[];
  min: number;
  max: number;
  axisTicks?: number;
  children: ReactNode | ReactNode[];
}

const degreeToRadians = (degree: number) => (Math.PI / 180) * degree;

export function RadarChart (props :RadarChartProps) {
  const {
    className,
    children,
    chartClassName,
    useCanvas,
    categories,
    min,
    max,
    axisTicks = 5,
    ...rest
  } = props;

  const [wrapperBounds, setWrapperBounds] = useState<DOMRect>();
  const handleWrapper = useCallback((node: HTMLDivElement) => {
    if (node) setWrapperBounds(node.getBoundingClientRect());
  }, [setWrapperBounds]);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  const canvasRefreshCounter = useRef(0);

  const width = wrapperBounds?.width || 0;
  const paddingForLabels = 150;

  const getValuePointPosition = useCallback(({ category, value }: RadarChartValue) => {
    let chartWidth = width;
    if (useCanvas) chartWidth *= window.devicePixelRatio || 1;

    const center = chartWidth / 2;
    const radius = (chartWidth - paddingForLabels) / 2;

    const categoryIndex = categories.findIndex(({ id }) => id == category);
    if (categoryIndex == -1) return { x: 0, y: 0 };

    const rotateDegrees = 360 / categories.length;
    const degree = rotateDegrees * categoryIndex * -1;
    const scale = (value - min) / (max - min);

    const radXDegree = degreeToRadians(degree + 90);
    const radYDegree = degreeToRadians(degree + 90 + 180);

    return {
      x: center + Math.cos(radXDegree) * radius * scale,
      y: center + Math.sin(radYDegree) * radius * scale
    };
  }, [categories, width, useCanvas]);

  const contextValue: RadarChartContextType = {
    chartWidth: width,
    chartHeight: width,

    canvasRef,
    isCanvas: !!useCanvas,
    canvasRefreshCounter: canvasRefreshCounter.current,

    categories,
    getValuePointPosition
  };

  const chartChildren = (
    <RadarChartContext.Provider value={contextValue}>
      <RadarAxis
        min={min}
        max={max}
        ticks={axisTicks} />
      {children}
    </RadarChartContext.Provider>
  );

  if (useCanvas) {
    const dpr = window.devicePixelRatio || 1;

    const canvasWidth = width * dpr;
    const canvasHeight = width * dpr;
    const style = { width, height: width };

    return (
      <div
        ref={handleWrapper}
        className={className}>
        <canvas
          width={canvasWidth}
          height={canvasHeight}
          style={style}
          className={chartClassName}
          ref={canvasRef}
          {...rest}>
          {chartChildren}
        </canvas>
      </div>
    );
  }

  return (
    <div
      ref={handleWrapper}
      className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${width} ${width}`}
        className={chartClassName}
        ref={svgRef}
        {...rest}>
        {chartChildren}
      </svg>
    </div>
  );
};
