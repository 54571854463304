import React, { PropsWithChildren, useState } from 'react'

import { ButtonV2 } from './ButtonV2'

export type ControlsCallbacks = { prev: () => void; next: () => void; goToStep: (step: number) => void }

type StepProps = {
  children: React.ReactNode
  controls?: (callbacks: ControlsCallbacks) => React.ReactNode
  header?: string
  hideControls?: boolean
  skip?: boolean
  name?: string
}

interface WizardProps extends PropsWithChildren {
  className?: string
  contextValue?: any
}

const WizardContext = React.createContext(undefined)

interface WizardBaseContextType {
  nextStep: () => void
  prevStep: () => void
  goToStep: (step: number) => void
  currentStep: number
  setCurrentStep: (step: number) => void
  steps: StepProps[]
}
interface ProviderProps extends React.PropsWithChildren {
  value: any
}

export function WizardContextProvider(props: ProviderProps) {
  const { children, value } = props

  return <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
}

export function useWizardContext<ContextType>() {
  const context = React.useContext(WizardContext)

  if (!context) {
    throw new Error('useWizardContext can only be called within a WizardContextProvider')
  }

  return context as ContextType & WizardBaseContextType
}

export function Wizard({ children, className, contextValue }: WizardProps) {
  const [currentStep, setCurrentStep] = useState(0)

  const steps = React.Children.toArray(children)

  function nextStep() {
    if (currentStep === steps.length - 1) {
      console.error('Cannot call nextStep on the last step')
      return
    }
    setCurrentStep((prevStep) => prevStep + 1)
  }

  function prevStep() {
    if (currentStep === 0) {
      console.error('Cannot call prevStep on the first step')
      return
    }
    setCurrentStep((prevStep) => prevStep - 1)
  }

  function goToStep(step: number) {
    setCurrentStep(step)
  }

  return (
    <WizardContextProvider
      value={{
        ...contextValue,
        steps,
        currentStep,
        setCurrentStep,
        nextStep,
        prevStep,
        goToStep,
      }}
    >
      <div className={className}>{steps[currentStep]}</div>
    </WizardContextProvider>
  )
}

export function Step({ children, controls, header, hideControls = false }: StepProps) {
  const { nextStep: next, prevStep: prev, goToStep } = useWizardContext()

  return (
    <>
      {header !== undefined ? <h3 className="pb-4 text-xl font-bold">{header}</h3> : null}
      {children}
      {!hideControls ? <Controls controls={controls && controls({ prev, next, goToStep })} /> : null}
    </>
  )
}

type ControlsProps = { controls?: React.ReactNode }

export function Controls({ controls, children }: PropsWithChildren<ControlsProps>) {
  return (
    <div className="flex w-full justify-between gap-lg py-lg">{children !== undefined ? children : controls ? controls : <DefaultControls />}</div>
  )
}

function DefaultControls() {
  const { prevStep, nextStep } = useWizardContext()

  return (
    <>
      <ButtonV2 variant="secondary" onClick={prevStep}>
        Previous
      </ButtonV2>
      <ButtonV2 variant="primary" onClick={nextStep}>
        Next
      </ButtonV2>
    </>
  )
}

Wizard.Controls = Controls
Wizard.Step = Step
