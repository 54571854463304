import { useContext, useId } from 'react';
import { HeatMapContext, HeatMapTile, calculateColorFromPercent } from './';
import { ChartDataSourceContext } from '../Chart';

type HeatMapProps = {
  tooltips?: boolean,
  lowerBound: number,
  lowerFill: string,
  upperBound: number,
  upperFill: string
}

export function HeatMap(props: HeatMapProps) {
  const {
    tooltips = false,
    lowerBound,
    lowerFill,
    upperBound,
    upperFill
  } = props;

  const id = useId();

  const { tileWidth, tileHeight, tilePadding, numberOfTiles, numberOfTilesPerRow } = useContext(HeatMapContext);
  const { data, valueScale, timeScale, tooltipLabel } = useContext(ChartDataSourceContext);

  if (data.length == 0 || !valueScale || !timeScale) return null;

  const { min: minDate, max: maxDate } = timeScale;

  const msTileSize = (maxDate - minDate) / numberOfTiles;
  const tiles = [];

  for (let tileIndex = 0; tileIndex < numberOfTiles; tileIndex += 1) {
    const rowIndex = Math.floor(tileIndex / numberOfTilesPerRow),
      tileIndexInRow = tileIndex % numberOfTilesPerRow;

    const tileStart = minDate + (tileIndex * msTileSize),
      tileEnd = tileStart + msTileSize,
      tileRange = tileEnd - tileStart;

    const dataBeforeRange = data.filter(({ date }) => tileStart > date);

    let valueBeforeRange = 0;
    if (dataBeforeRange.length > 0) {
      const lastDatum = dataBeforeRange[dataBeforeRange.length - 1];
      valueBeforeRange = lastDatum.value;
    }

    const dataInRange = data.filter(({ date }) => tileStart <= date && tileEnd > date);

    const weightedValuesInRange = [];

    if (dataInRange.length > 0) {
      weightedValuesInRange.push({
        value: valueBeforeRange,
        rangePercentage: (dataInRange[0].date - tileStart) / tileRange
      });

      for (let datumIndex = 0; datumIndex < dataInRange.length; datumIndex += 1) {
        const datum = dataInRange[datumIndex];
        let nextDate = tileEnd;
        const nextDatum = dataInRange[datumIndex + 1];
        if (nextDatum) nextDate = nextDatum.date;
        weightedValuesInRange.push({
          value: datum.value,
          rangePercentage: (nextDate - datum.date) / tileRange
        });
      }
    } else {
      weightedValuesInRange.push({
        value: valueBeforeRange,
        rangePercentage: 1
      });
    }

    const tileAverageValue = weightedValuesInRange.reduce((averageValue, { value, rangePercentage }) => {
      return averageValue + (value * rangePercentage);
    }, 0);

    const percent = (tileAverageValue - lowerBound) / (upperBound - lowerBound);
    const tileColor = calculateColorFromPercent(lowerFill, upperFill, percent);

    tiles.push({
      start: tileStart,
      end: tileEnd,
      fill: tileColor,
      width: tileWidth,
      height: tileHeight,
      averageValue: tileAverageValue.toFixed(2),
      x: tileIndexInRow * (tileWidth + tilePadding),
      y: rowIndex * (tileHeight + tilePadding)
    });
  }

  return (
    <>
      {
        tiles.map(({ start, end, fill, width, height, averageValue, x, y }, index) => (
          <HeatMapTile
            key={index}
            segmentId={id}
            tileIndex={index}
            start={start}
            end={end}
            width={width}
            height={height}
            x={x}
            y={y}
            fill={fill}
            tooltip={tooltips}
            tooltipLabel={`${tooltipLabel}: ${averageValue}`} />
        ))
      }
    </>
  );
};
