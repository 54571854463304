'use client'

import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { CellEditingStoppedEvent, ColDef, RowClassParams, ValueFormatterParams } from '@ag-grid-community/core'

type Params = {
  columnDefs: ColDef[]
  defaultColDef: ColDef
  setRowData: Dispatch<SetStateAction<any[]>>
}

// This hook is used to implement a pinned row to the bottom of a grid that allows the user to add a new row of data.
// The InlineAddRowExample component in apps/engage/src/app-components/GridExamples.tsx demonstrates the logic that is encapsulated in this hook.
// All of the set up in that component can be replaced by just calling this hook and spreading the returned `pinnedRowGridOptions` object into the gridOptions prop of the AGGridWrapper component.

export function usePinnedAddRow({ columnDefs, setRowData, defaultColDef }: Params) {
  const [inputRow, setInputRow] = useState({})

  // This function is used to create a placeholder value for the pinned row cells.
  function createPinnedCellPlaceholder({ colDef }: ValueFormatterParams) {
    if (colDef.field === undefined) return ''
    return `${colDef.field[0].toUpperCase() + colDef.field.slice(1)}...`
  }

  /*  
  This function is used to determine if a pinned row cell is empty.
  It can be used in the valueFormatter conjunciton with createPinnedCellPlaceholder to display a placeholder value in the cell, like so:

  valueFormatter: (params) =>
  isEmptyPinnedCell(params)
        ? createPinnedCellPlaceholder(params)
        : undefined,
  */
  function isEmptyPinnedCell(params: ValueFormatterParams) {
    return (params.node?.rowPinned === 'bottom' && params.value == null) || (params.node?.rowPinned === 'top' && params.value === '')
  }

  // Some basic styling for the pinned row
  const getRowStyle = useCallback((params: RowClassParams) => {
    if (!params.node.rowPinned) return {}
    return { fontWeight: 'bold', fontStyle: 'italic' }
  }, [])

  // This function is used to determine if the user has completed the pinned row data entry by checking if there is a value in each column.
  const isPinnedRowDataCompleted = useCallback(
    (params: CellEditingStoppedEvent) => {
      if (params.rowPinned !== 'bottom') return
      return columnDefs.every((def) => (inputRow as Record<string, any>)[def.field as string])
    },
    [columnDefs, inputRow],
  )

  // This function is used to add the new row to the grid data when the user completes the pinned row data entry, and then clears the pinned row data.
  const onCellEditingStopped = useCallback(
    (params: CellEditingStoppedEvent) => {
      if (isPinnedRowDataCompleted(params)) {
        setRowData((prev) => [...prev, inputRow])
        setInputRow({})
      }
    },
    [inputRow, isPinnedRowDataCompleted],
  )

  return {
    // you can just return this object and spread it into your grid component for simple use
    pinnedRowGridOptions: {
      pinnedBottomRowData: [inputRow],
      onCellEditingStopped,
      getRowStyle,
      defaultColDef: {
        ...defaultColDef,
        valueFormatter: (params: ValueFormatterParams) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
      },
    },
    // or you can return these functions and use them individually as needed
    createPinnedCellPlaceholder,
    isEmptyPinnedCell,
    getRowStyle,
    isPinnedRowDataCompleted,
    onCellEditingStopped,
  }
}
