import React from 'react'

import { AGGridClientSide, AGGridServerSide } from '@fsg/gui-bits'

import { AGGridWrapperProps } from '../../types' // REVIEW >> For some reason the alias caused Vercel builds to fail

// * AG Grid modules requires separate imports and module registration for the different row model types...
// * Which is why this wrapper became a proxy for serving the appropriate version based solely on rowModelType param

export function AGGridWrapper<ContextType>(props: AGGridWrapperProps) {
  switch (props.rowModelType) {
    case 'serverSide':
      return <AGGridServerSide<ContextType> {...props}></AGGridServerSide>
      break
    // * May eventually need to expand to utilize the other two row mode types...
    // case "infinite":
    //   return <AGGridInfinite {...props}></AGGridInfinite>
    //   break
    // case "viewport":
    //   return <AGGridViewport {...props}></AGGridViewport>
    //   break

    default:
      // * Default to client side
      return <AGGridClientSide<ContextType> {...props}></AGGridClientSide>
      break
  }
}
