import React, { ChangeEvent, forwardRef } from 'react'

interface CurrencyInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  decimals?: number
  value?: string
}

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(({ decimals, value, onChange, ...props }, ref) => {
  const formatCurrency = (val: string): string => {
    if (val === '' || val === null || val === undefined) return val
    let stringValue = val.toString()

     // Note: Fields in the db which are specified as currency will always return the value with decimals. In this case, if the component does not specify decimals, or specifies 0 decimals, we first need to strip the decimals out from the value returned by the db.
     if (stringValue.includes('.') && (!decimals)) {
      stringValue = stringValue.split('.')[0]
    }

    let formatted = stringValue.replace(/[^\d.]/g, '')
    
   
    if (decimals !== undefined && decimals !== 0) {
      const parts = formatted.split('.')
      if (parts.length > 2) {
        formatted = `${parts[0]}.${parts.slice(1).join('')}`
      }
      if (parts[1] && parts[1].length > decimals) {
        formatted = `${parts[0]}.${parts[1].slice(0, decimals)}`
      }
    } else {
      formatted = formatted.replace(/\./g, '')
    }

    const numberParts = formatted.split('.')
    numberParts[0] = numberParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return numberParts.join('.')
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatCurrency(e.target.value)

    // Create a new "fake" event with the formatted value
    const newEvent = {
      ...e,
      target: {
        ...e.target,
        value: formattedValue,
      },
    }

    if (onChange) {
      onChange(newEvent as ChangeEvent<HTMLInputElement>)
    }
  }

  return (
    <input
      type="text"
      ref={ref}
      value={value !== undefined ? formatCurrency(value) : undefined}
      onChange={handleChange}
      placeholder={decimals !== undefined ? `0.${'0'.repeat(decimals)}` : '0'}
      {...props}
    />
  )
})
CurrencyInput.displayName = 'CurrencyInput'
