'use client'
import { createContext } from 'react';
import { YScale, XScale } from './';

export type DataPoint = {
  date: number;
  value: number;
  label?: string;
}

type ChartDataSourceContextType = {
  data: DataPoint[];
  mapValue?: (value: number) => string;
  tooltipLabel?: string;
  tooltipValueLabel?: string;
  valueScale: YScale;
  timeScale?: XScale;
}

export const ChartDataSourceContext = createContext<ChartDataSourceContextType>({
  data: [],
  valueScale: {
    min: -Infinity,
    max: Infinity
  }
});
