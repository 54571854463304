import { useContext, forwardRef } from 'react';
import { ChartDataSourceContext } from '../Chart';
import { Line, LineProps } from '../Line';

type GappedLineProps = LineProps;

export const GappedLine = forwardRef<SVGPolylineElement, GappedLineProps>(
  function GrappedLine(props, _ref) {
    const { data: combinedData, ...contextValue } = useContext(ChartDataSourceContext);

    const dataSegments = combinedData.reduce<Array<Array<typeof combinedData[0]>>>((segmentsArray, datum) => {
      if (datum.value) {
        segmentsArray[segmentsArray.length - 1].push(datum);
      } else if (segmentsArray[segmentsArray.length - 1].length > 0) {
        const { value: lastValue } = segmentsArray[segmentsArray.length - 1][0];
        segmentsArray[segmentsArray.length - 1].push({
          date: datum.date - 1,
          value: lastValue
        });
        segmentsArray.push([]);
      }

      return segmentsArray;
    }, [[]]);

    return (
      <>
        {
          dataSegments.map((data, index) => (
            <ChartDataSourceContext.Provider key={index} value={{ ...contextValue, data }}>
              <Line ref={_ref} {...props} />
            </ChartDataSourceContext.Provider>
          ))
        }
      </>
    );
  });
