
import { FC, forwardRef, SVGAttributes, ReactElement, ReactNode } from 'react'
import cn from 'classnames/bind'
import { Card, CardProps } from '../Card'
import { ArrowDown, ArrowUp } from '@fsg/icons'
import moduleStyles from './KPICard.module.scss'
// import { Modal }from '@atoms/Modal'

const cx = cn.bind(moduleStyles)

export type KPICardProps = Omit<CardProps, 'children'> & {
  icon?: FC<SVGAttributes<SVGElement>>
  other?: ReactElement
  component?: FC<CardProps> | string
  label: string | ReactElement
  labelClassName?: string
  primary: ReactNode
  secondary?: string
  direction?: 'up' | 'down'
  secondaryColor?: 'red' | 'green' | 'black'
  helpText?: ReactNode
  primaryColor?: 'red' | 'green' | 'black'
  secondaryClassname?: string
}

 export const KPICard = forwardRef<HTMLDivElement, KPICardProps>(
  function KPICard(props, _ref) {
  const {
    icon: Icon,
    other,
    component: Component = Card,
    className,
    label,
    labelClassName,
    primary,
    primaryColor = 'black',
    secondary,
    secondaryColor = 'green',
    secondaryClassname,
    direction,
    helpText,
    ...rest
  } = props

  // const helpRef = useRef<HTMLSpanElement>(null)

  const cardClassName = cx('m--kpi-card', className)

  return (
    <Component ref={_ref} className={cx(cardClassName)} {...rest}>
      <div>
        <div className={cx('m--label', 'font-bold', labelClassName)}>
          {label}
          {/* {helpText && (
            <>
              <Info className={styles['help-text']} width="16" stroke="#ACAFB5" ref={helpRef} />
              <Modal closeButton={true} headerText={label} anchor={helpRef}>
                {helpText}
              </Modal>
            </>
          )} */}
        </div>
        <div className={cx('m--value-wrapper')}>
          {Icon ? (
            <div className={cx('m--icon-wrapper')}>
              <Icon width={26} height={26} />
            </div>
          ) : other ? (
            <div className={cx('m--other-wrapper')}>{other}</div>
          ) : null}
          <div className={cx('m--stacked-vertically')}>
            <div className={cx('m--primary', `primary--${primaryColor}`)}>
              <span>{primary}</span>
            </div>
            {secondary !== undefined || direction ? (
              <div
                className={cx(
                  secondaryClassname,
                  'm--secondary',
                  `m--secondary--${secondaryColor}`,
                  {
                    ['m--secondary--long']: secondary && secondary.length > 10,
                  },
                )}
              >
                {secondary !== undefined ? <span>{secondary}</span> : null}
                {direction == 'down' ? <ArrowDown /> : null}
                {direction == 'up' ? <ArrowUp /> : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Component>
  )
})
