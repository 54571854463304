import { ReactNode } from 'react'
import cn from 'classnames'

import moduleStyles from './Tab.module.scss'

type TabProps = {
  children: ReactNode
  label?: string | ReactNode
  active?: boolean
}

export function Tab(props: TabProps) {
  const { children, active, label } = props

  return <>{children}</>
}
