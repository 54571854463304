import { FC, SVGAttributes } from 'react'
import Link, { LinkProps } from 'next/link'
import cn from 'classnames'
import { Create } from '@fsg/icons'
import moduleStyles from './NavLabel.module.scss'

export type NavLabelProps = {
  className?: string
  icon?: FC<SVGAttributes<SVGElement>>
  createProps?: LinkProps
  children?: string
}

export function NavLabel(props: NavLabelProps) {
  const { className, icon: Icon, createProps, children } = props

  const navLabelClassName = cn(moduleStyles['m--nav-label'], 'font-2xl font-bold', className)

  return (
    <div className={navLabelClassName}>
      {Icon ? (
        <Icon className={moduleStyles['m--icon']} width={18} height={18} />
      ) : (
        <span className={moduleStyles['m--icon-placeholder']} />
      )}
      <span className={moduleStyles['m--display-label']}>{children}</span>
      {createProps ? (
        <Link prefetch={false} className={moduleStyles['create']} {...createProps}>
          <Create width={16} height={16} />
        </Link>
      ) : null}
    </div>
  )
}
