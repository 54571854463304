import { useRef, useCallback, useState, useContext, ReactNode } from 'react';
import { ChartContext } from '../Chart';
import { BarContext , BarContextType} from './ChartBarContext';

export type BarProviderProps = {
  count?: number;
  stacked?: boolean;
  barWidth?: number;
  barPadding?: number;
  barGroupPadding?: number;
  children?: ReactNode | ReactNode[];
}

export function BarProvider(props: BarProviderProps) {
  const { 
    children, 
    count, 
    stacked = false, 
    barPadding = 2, 
    barGroupPadding = 6,
    barWidth
  } = props;

  const { chartWidth } = useContext(ChartContext);
  const barSegmentIds = useRef<string[]>([]);
  const barHeightMaps = useRef<Map<string, number[]>>(new Map());
  const [forcedUpdateCounter, setForcedUpdateCounter] = useState(0);
  
  const forceUpdate = useCallback(() => {
    setForcedUpdateCounter(forcedUpdateCounter + 1);
  }, [setForcedUpdateCounter, forcedUpdateCounter]);

  const registerBarSegment = useCallback((barSegmentId: string) => {
    barSegmentIds.current = barSegmentIds.current.filter((id) => id !== barSegmentId).concat(barSegmentId);
    forceUpdate();
  }, [barSegmentIds, forceUpdate]);

  const unregisterBarSegment = useCallback((barSegmentId: string) => {
    barSegmentIds.current = barSegmentIds.current.filter((id) => id !== barSegmentId);
    forceUpdate();
  }, [barSegmentIds, forceUpdate]);

  const getBarWidth = useCallback((): number => {
    if (barWidth) return barWidth;
    if (!count) return 4;

    const numberOfBarSegments = barSegmentIds.current.length;

    if (numberOfBarSegments > 1 && !stacked) {
      const clusterWidth = getBarClusterWidth();
      return (clusterWidth - ((numberOfBarSegments - 1) * barPadding)) / numberOfBarSegments;
    } else {
      return (chartWidth - ((count - 1) * barPadding)) / count;
    }
  }, [barWidth, count, chartWidth]);

  const getBarClusterWidth = useCallback((): number => {
    const numberOfBarSegments = barSegmentIds.current.length;

    const numberOfBars = count || 1;

    if (numberOfBarSegments < 2) return getBarWidth();
    return (chartWidth - ((numberOfBars - 1) * barGroupPadding)) / numberOfBars;
  }, [chartWidth, count, barGroupPadding]);

  const getBarXOffset = useCallback((barSegmentId: string) => {
    if (stacked) return 0;

    const barIndex = barSegmentIds.current.indexOf(barSegmentId);
    if (barIndex < 0) return 0;
    return barIndex * (getBarWidth() + barPadding);
  }, [stacked, barSegmentIds, getBarWidth, barPadding]);

  const getBarYOffset = useCallback((barSegmentId: string, barIndex: number, value: number) => {
    if (!stacked) return 0;

    if (!barHeightMaps.current.has(barSegmentId)) barHeightMaps.current.set(barSegmentId, []);
    const heightArray = barHeightMaps.current.get(barSegmentId);

    if (!heightArray) return 0;
    heightArray[barIndex] = value;
    barHeightMaps.current.set(barSegmentId, heightArray);

    const followingBarIds = barSegmentIds.current.slice(0, barSegmentIds.current.indexOf(barSegmentId));

    const offset = followingBarIds.reduce((total, barId) => {
      const heightArray = barHeightMaps.current.get(barId);
      if (!heightArray) return total;
      return total + (heightArray[barIndex] || 0);
    }, 0);

    return offset;
  }, [stacked, barHeightMaps, barSegmentIds]);

  const barDisplayWidth = getBarWidth();
  const barClusterWidth = getBarClusterWidth();
  const numberOfBarSegments = barSegmentIds.current.length;
  let numberOfBars = count || 1;

  if (barDisplayWidth && !numberOfBars) {
    if (numberOfBarSegments > 1 && !stacked) {
      const clusterWidth = (numberOfBarSegments * (barDisplayWidth + barPadding)) - barPadding + barGroupPadding;
      numberOfBars = Math.floor(Math.max(chartWidth, 0) / clusterWidth);
    } else {
      numberOfBars = Math.floor(Math.max(chartWidth, 0) / (barDisplayWidth + barPadding));
    }
  }

  const contextValue: BarContextType = {
    registerBarSegment,
    unregisterBarSegment,
    getBarXOffset,
    getBarYOffset,
    numberOfBars,
    barDisplayWidth,
    barClusterWidth
  };

  return (
    <BarContext.Provider value={ contextValue }>
      { children }
    </BarContext.Provider>
  );
};
