import { forwardRef, ReactNode, AnchorHTMLAttributes } from 'react'
import Link from 'next/link'
import cn from 'classnames/bind'
import { PhoneOutgoing, Mail, PopOut } from '@fsg/icons'
import moduleStyles from './Anchor.module.scss'

const cx = cn.bind(moduleStyles)

type AnchorProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'className' | 'children'> & {
  className?: string
  children: string
  href: string
  target?: string
  withIcon?: boolean
  icon?: ReactNode
  useNavLink?: boolean
}

export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(function Anchor(props: AnchorProps, _ref) {
  const { className, children, href, target, useNavLink = false, withIcon = true, icon = null, ...rest } = props

  let hasIcon = false
  let defaultIcon: ReactNode = null

  if (withIcon) {
    if (href?.toLowerCase().includes('mailto:')) {
      hasIcon = true
      defaultIcon = <Mail />
    } else if (href?.toLowerCase().includes('tel:')) {
      hasIcon = true
      defaultIcon = <PhoneOutgoing />
    } else if (href?.toLowerCase().startsWith('http') || target?.toLowerCase() === '_blank') {
      hasIcon = true
      defaultIcon = <PopOut />
    } else if (icon) {
      hasIcon = true
      defaultIcon = icon
    }
  }

  const AnchorClassName = cx('m--Anchor', 'text-black', className, {
    'm--Anchor--has-icon': !!hasIcon,
  })

  return useNavLink ? (
    <Link {...rest} href={href} ref={_ref} className={AnchorClassName}>
      <span className={cx('m--Anchor__text')}>{children}</span>
      {hasIcon ? <span className={cx('m--Anchor__icon')}>{defaultIcon}</span> : null}
    </Link>
  ) : (
    <a {...rest} href={href} target={target} ref={_ref} className={AnchorClassName}>
      <span className={cx('m--Anchor__text')}>{children}</span>
      {hasIcon ? <span className={cx('m--Anchor__icon', 'text-gray-darker-200')}>{defaultIcon}</span> : null}
    </a>
  )
})
