import { Children, FC, useId, useState } from 'react'
import cn from 'classnames'
import Select, { components } from 'react-select'
import { LoadingSpinner } from '../LoadingSpinner'
import { ChevronDown } from '@fsg/icons'
import moduleStyles from './FancySelect.module.scss'

type OptionType = { value: string | number; label: string }
export type OptionsType = OptionType[]

type ValueType = OptionType | OptionsType | null | undefined | string | number
type ActionTypes =
  | 'clear'
  | 'create-option'
  | 'deselect-option'
  | 'pop-value'
  | 'remove-value'
  | 'select-option'
  | 'set-value'

export type FancySelectProps = {
  loading?: boolean
  icon?: FC
  placeholder?: string
  label?: string
  disabled?: boolean
  clearValue?: () => void
  getStyles?: () => any
  getValue?: () => ValueType
  hasValue?: boolean
  isMulti?: boolean
  options?: OptionsType
  selectOption?: (option: OptionType) => void
  selectProps?: any
  setValue?: (value: ValueType, actions: ActionTypes) => void
  onInputChange?: (e: any) => void
  onChange?: (e: any) => void
  onMenuOpen?: () => void
  onMenuClose?: () => void
  emotion?: any
  className?: string
  value?: ValueType
}

const { ValueContainer, Placeholder } = components

function CustomValueContainer({ children, ...rest }: any) {
  return (
    <ValueContainer {...rest}>
      <Placeholder {...rest} isFocused={rest.isFocused}>
        {rest.selectProps.placeholder}
      </Placeholder>
      {Children.map(children, child => (child && child.key !== 'placeholder' ? child : null))}
    </ValueContainer>
  )
}

export function FancySelect(props: FancySelectProps) {
  const { label, loading, icon: Icon, className, disabled = false, selectProps } = props

  const id = useId()
  const [focused, setFocused] = useState(false)

  const labelClassName = cn(moduleStyles['fancy-select-input'], className, {
    // [styles['fancy-select-input--focus']]: focused,
    // [styles['fancy-select-input--disabled']]: selectProps.disabled,
    // [styles['fancy-select-input--multiple']]: selectProps.multiple,
    [moduleStyles['fancy-select-input--loading']]: loading,
    // [fancy['fancy-select-input--dirty']]: dirty,
    [moduleStyles['fancy-select-input--with-label']]: !!label,
  })

  return (
    <>
      <label htmlFor={id} className={labelClassName}>
        {loading ? (
          <div className={moduleStyles['loading-overlay']}>
            <LoadingSpinner size="small" />
          </div>
        ) : null}
        {label ? <span className={moduleStyles['label']}>{label}</span> : null}
        <div>
          {Icon ? <Icon /> : null}
          <Select
            {...props}
            {...selectProps}
            isDisabled={disabled}
            inputId={id}
            className={'fancy-select-actual-select'}
            classNamePrefix={'fancy-select-actual-select'}
            styles={{
              control: (base: object) => ({
                ...base,
                height: '32px',
                minHeight: '32px',
                alignItems: 'center',
                position: 'relative',
                padding: '0px 8px',
                display: 'inline-flex',
                border: '1px solid #ACAFB555',
                borderRadius: '8px',
                background: 'linear-gradient(327.19deg, #FBFCFE 5.16%, #FEFEFE 91.23%)',
                transition: 'all 0.1s ease-in-out',
                boxShadow: '0px 1px 5px -1px rgba(0, 0, 0, 0.05)',
                overflow: 'hidden',
              }),
              valueContainer: (base: object) => ({
                ...base,
                padding: '0px',
                display: 'flex',
              }),
              placeholder: (base: object) => ({
                ...base,
                fontFamily: 'Barlow',
                fontSize: '14px',
                display: 'flex',
              }),
              indicatorSeparator: () => ({
                display: 'none',
              }),
              indicatorsContainer: (base: object) => ({
                ...base,
                padding: '5px',
              }),
              clearIndicator: (base: object) => ({
                ...base,
                padding: '0px',
                margin: '0px',
              }),
              singleValue: (base: object) => ({
                ...base,
                gridArea: null,
                display: 'flex',
                fontWeight: 700,
                fontSize: '14px',
              }),
            }}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            isFocused={focused}
            components={{
              DropdownIndicator: () => <ChevronDown width={16} height={16} />,
              ValueContainer: CustomValueContainer,
            }}
          ></Select>
        </div>
      </label>
    </>
  )
}
