import { Children, ReactElement, JSXElementConstructor, cloneElement, ReactNode } from 'react';
import cn from 'classnames';
import moduleStyles from './ButtonGroup.module.scss';
import { Button, ButtonProps } from '../Button';
import { SpookyTextInput, SpookyTextInputProps } from '../SpookyTextInput';

export type ButtonGroupProps = {
  className?: string;
  children: ReactNode;
}

export function ButtonGroup (props: ButtonGroupProps) {
  const { className, children } = props;

  return (
    <div className={cn(moduleStyles['m--button-group'], className)}>
      {
        Children.map(children, (child) => {
          if (!child || !(typeof child == 'object')) return null;
          if (!Object.prototype.hasOwnProperty.call(child, 'type')) return null;

          const node = child as ReactElement<ButtonProps | SpookyTextInputProps, JSXElementConstructor<ButtonProps | SpookyTextInputProps>>;

          if (node.type == Button) {
            const buttonProps = node.props as ButtonProps;

            return cloneElement(node, {
              ...buttonProps,
              className: cn(moduleStyles['m--button'], buttonProps.className, {
                [moduleStyles['m--button--ghost-with-icon']]:
                  buttonProps.withIcon && buttonProps.buttonType == 'ghost',
                [moduleStyles['m--button--active']]: buttonProps.active,
              }),
            })
          } else if (node.type == SpookyTextInput) {
            const inputProps = node.props as SpookyTextInputProps;

            return cloneElement(node, {
              ...inputProps,
              className: cn(moduleStyles['m--spooky-input'], inputProps.className),
            })
          }
          return null;
        })
      }
    </div>
  );
};
