import { useContext, useId, useEffect, useState } from 'react';
import {ChartContext, ChartDataSourceContext} from '../';
import { DateLike, formatDateHourOnly, translateColorToSafeString } from '../../helpers';

type XAxisProps = {
  maxDateTicks?: number;
  dateFormat?: (d: DateLike) => string;
  label?: string;
  gridStroke?: string;
  gridStrokeWidth?: number;
  textColor?: string;
}

export function XAxis(props :XAxisProps) {
  const {
    dateFormat = (date) => formatDateHourOnly(date),
    gridStroke = '#F1F1F5',
    gridStrokeWidth = 2,
    textColor = '#6F7278',
    label = ''
  } = props;

  let { maxDateTicks = 5 } = props;

  const { registerXAxis, unregisterXAxis, xAxesPositioning, chartWidth, chartHeight, chartPadTop, chartPadLeft, isCanvas, canvasRef, canvasRefreshCounter } = useContext(ChartContext);
  const { timeScale } = useContext(ChartDataSourceContext);

  const id = useId();
  const [registered, setRegistered] = useState(false);

  useEffect(() => {
    try {
      registerXAxis(id);
      setRegistered(true);
    } catch (e) {
      setRegistered(false);
    }
    return () => unregisterXAxis(id);
  }, [id]);

  useEffect(() => {
    if (!isCanvas || !canvasRef?.current) return;
    if (!registered || !timeScale) return;

    const positioning = xAxesPositioning[id];

    const labelWidth = dateFormat(timeScale.min).length * 7;

    maxDateTicks = Math.min(Math.floor(chartWidth / labelWidth), maxDateTicks);

    const range = timeScale.max - timeScale.min,
      step = Math.round(range / maxDateTicks);

    if (range == -Infinity) return;

    const ctx = canvasRef.current.getContext('2d');
    const dpr = window.devicePixelRatio || 1;

    if (!ctx) return;

    for (let i = 1; i < maxDateTicks; i++) {
      const left = 1 - (((maxDateTicks - i) * step) / range);

      ctx.beginPath();
      ctx.strokeStyle = translateColorToSafeString(gridStroke, canvasRef.current);
      ctx.moveTo(((left * chartWidth) + chartPadLeft) * dpr, chartPadTop * dpr);
      ctx.lineTo(((left * chartWidth) + chartPadLeft) * dpr, (chartHeight + chartPadTop) * dpr);
      ctx.stroke();

      if (positioning) {
        ctx.beginPath();
        ctx.strokeStyle = translateColorToSafeString(textColor, canvasRef.current);
        ctx.fillStyle = translateColorToSafeString(textColor, canvasRef.current);
        ctx.lineWidth = 1;
        ctx.textAlign = 'center';
        ctx.font = `${ dpr * 12 }px Barlow`;
        ctx.fillText(
          dateFormat(timeScale.max - ((maxDateTicks - i) * step)),
          ((left * chartWidth) + chartPadLeft) * dpr,
          positioning.top * dpr
        );
        ctx.stroke();
      }
    }
  }, [isCanvas, canvasRef, canvasRefreshCounter, chartWidth, chartHeight, chartPadTop, chartPadLeft, timeScale]);

  if (isCanvas) return null;

  if (!registered || !timeScale) return null;

  const positioning = xAxesPositioning[id];
  const axisNodes = [];

  const labelWidth = dateFormat(timeScale.min).length * 7;

  maxDateTicks = Math.min(Math.floor(chartWidth / labelWidth), maxDateTicks);

  const range = timeScale.max - timeScale.min,
    step = Math.round(range / maxDateTicks);

  if (range == -Infinity) return null;

  for (let i = 1; i < maxDateTicks; i++) {
    const left = 1 - (((maxDateTicks - i) * step) / range);

    axisNodes.push(
      <polyline
        key={`x-${ id }-${ i }`}
        stroke={gridStroke}
        strokeWidth={gridStrokeWidth}
        points={[
          { top: 0, left },
          { top: 1, left }
        ].map((p) => `${ (p.left * chartWidth) + chartPadLeft },${ (p.top * chartHeight) + chartPadTop }`).join(' ')} />
    );

    if (positioning) {
      const { top } = positioning;

      axisNodes.push(
        <text
          key={`x-label-${ id }-${ i }`}
          fill={textColor}
          style={{
            fontSize: label ? 8 : 12,
          }}
          textAnchor="middle"
          x={(left * chartWidth) + chartPadLeft}
          y={top - (label ? 7 : 0)}>
          {dateFormat(timeScale.max - ((maxDateTicks - i) * step))}
        </text>
      );
    }
  }
  if (label) {
    axisNodes.push(
      <text
        key={`x-label-${ id }`}
        fill={textColor}
        style={{
          fontSize: 12
        }}
        textAnchor="middle"
        x={chartWidth / 2 + chartPadLeft}
        y={positioning.top + 5}>
        {label}
      </text>
    );
  }

  return (
    <>
      {axisNodes}
    </>
  );
};
