import {
  FC,
  useState,
  forwardRef,
  useId,
  useRef,
  useCallback,
  FocusEvent,
  KeyboardEvent,
  InputHTMLAttributes,
  SVGAttributes,
} from 'react'
import cn from 'classnames'
import moduleStyles from './TextInput.module.scss'
import { Button } from '../Button'
import { LoadingSpinner } from '../LoadingSpinner'
// import { ToolTip } from '../ToolTip';
import { ChevronDown, ChevronUp } from '@fsg/icons'

export type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  loading?: boolean
  icon?: FC<SVGAttributes<SVGElement>>
  onEnter?: (e: KeyboardEvent<HTMLInputElement>) => void
  disabledReason?: string
  rightLabel?: string
  leftLabel?: string
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(function TextInput(
  props: TextInputProps,
  _ref,
) {
  const {
    id: idFromProps,
    icon: Icon,
    className,
    loading,
    type = 'text',
    step = 1,
    onChange,
    onEnter,
    onFocus,
    onBlur,
    disabledReason,
    rightLabel,
    leftLabel,
    ...rest
  } = props

  const value = `${rest.value || ''}`

  const labelRef = useRef<HTMLLabelElement>(null)
  const newId = useId()
  const id = idFromProps || newId
  const [focused, setFocused] = useState(false)

  const dirty = rest.value !== undefined && rest.value !== null && value.length > 0

  const labelClassName = cn(
    moduleStyles['text-input'],
    {
      [moduleStyles['text-input--number']]: type == 'number',
      [moduleStyles['text-input--focus']]: focused,
      [moduleStyles['text-input--disabled']]: rest.disabled,
      [moduleStyles['text-input--loading']]: loading,
      [moduleStyles['text-input--dirty']]: dirty,
    },
    className,
  )

  if (onEnter) {
    rest.onKeyDown = e => {
      return e.key == 'Enter' ? onEnter(e) : true
    }
  }

  const manualValueUpdateFromInnerNode = (node: Element, value: string) => {
    const label = node.closest(`.${moduleStyles['text-input']}`)
    if (!label) return
    const input = label.querySelector('input')
    if (!input) return
    const nativeInputValue = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value')
    if (!nativeInputValue || !nativeInputValue.set) return
    nativeInputValue.set.call(input, value)
    const inputEvent = new Event('input', { bubbles: true })
    input.dispatchEvent(inputEvent)
  }

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setFocused(true)
      onFocus && onFocus(event)
    },
    [setFocused, onFocus],
  )

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setFocused(false)
      onBlur && onBlur(event)
    },
    [setFocused, onBlur],
  )

  return (
    <>
      <label htmlFor={id} ref={labelRef} className={labelClassName}>
        {loading ? (
          <div className={moduleStyles['loading-overlay']}>
            <LoadingSpinner size="small" />
          </div>
        ) : null}
        {Icon ? <Icon /> : null}
        {leftLabel ? (
          <div className={moduleStyles['left-label']}>
            <span>{leftLabel}</span>
          </div>
        ) : null}
        <div className={moduleStyles['input-container']}>
          <input
            {...rest}
            ref={_ref}
            onChange={onChange}
            value={value}
            id={id}
            type={type}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </div>
        {type == 'number' ? (
          <div className={moduleStyles['controls']}>
            <Button
              withIcon
              buttonType="ghost"
              disabled={rest.disabled}
              onClick={event =>
                manualValueUpdateFromInnerNode(
                  event.currentTarget,
                  `${parseFloat(value) + parseFloat(`${step}`)}`,
                )
              }
            >
              <ChevronUp width="2rem" height="2rem" />
            </Button>
            <Button
              withIcon
              buttonType="ghost"
              disabled={rest.disabled}
              onClick={event =>
                manualValueUpdateFromInnerNode(
                  event.currentTarget,
                  `${parseFloat(value) - parseFloat(`${step}`)}`,
                )
              }
            >
              <ChevronDown width="2rem" height="2rem" />
            </Button>
          </div>
        ) : null}
        {rightLabel ? (
          <div className={moduleStyles['right-label']}>
            <span>{rightLabel}</span>
          </div>
        ) : null}
      </label>
      {/* {rest.disabled && disabledReason ? (
        <ToolTip delay={100} anchor={labelRef}>
          {disabledReason}
        </ToolTip>
      ) : null} */}
    </>
  )
})
