'use client'

import React from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

import { Button } from '@fsg/gui-bits'
import { ChevronsLeft, ChevronsRight, File, RiskPulseMonitor } from '@fsg/icons'
import { cn, isWindowWiderThan } from '@fsg/utils'

// !!! IMPORTANT !!!
// NOTE: This isn't really ready for gui-bits, but we're trying to normalize the app design pattern and this is a first step (ie I didn't want to create another app-component level NavbarV2 in /finance)

interface NavItemProps extends React.PropsWithChildren {
  className?: string
  href: string
  label: string
  icon: React.ElementType
  create?: React.ElementType
}

const NavDrawerContext = React.createContext<{ isOpen: boolean } | undefined>(undefined)

// TODO >> Got partway through auditing these CSS classes, but didn't quite finish
// TODO >> Could probably use CVA
// TODO >> I'd really like to do this by composing children, but couldn't see the clear way
function NavItem({ href, label, icon: Icon }: NavItemProps) {
  // const { isOpen } = React.useContext(NavDrawerContext)
  const context = React.useContext(NavDrawerContext)
  const isOpen = context ? context.isOpen : false
  const pathname = usePathname()
  const active = href === '/' ? pathname === href : pathname.startsWith(href)

  return (
    <div className="grid grid-cols-[1fr_auto]">
      <Link
        prefetch={false}
        href={href}
        // TODO >>Check that hover:bg-active-light is #ebf0fb
        // TODO >> Check that active:bg-active is #dae5fd
        className={cn(
          'col-span-full row-start-1  flex min-h-[40px] gap-md rounded-full px-4 py-3 text-2xl font-bold text-gray-darker-900 no-underline hover:bg-active-light',
          { 'bg-active text-primary': active },
        )}
      >
        <div className="flex items-center gap-md">
          <Icon className="m-0 text-3xl"></Icon>
          <span className={cn('transition-basic mx-3 whitespace-nowrap', { 'opacity-100': isOpen, 'opacity-0': !isOpen })}>{label}</span>
        </div>
      </Link>
      <div
        className={cn('transition-basic col-start-2 row-start-1 self-center overflow-hidden pr-2', {
          'w-full opacity-100': isOpen,
          'w-0 opacity-0': !isOpen,
        })}
      >
        {/* {Create ? (
          <div className={cn('rounded-lg p-xs text-3xl', { 'bg-gray-lightest': !active })}>
            <Create navMode={true} />
          </div>
        ) : null} */}
      </div>
    </div>
  )
}

export function NavbarV2() {
  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    // TODO >> Unneeded.  Do this with CSS (lg:css-class).
    if (!window) return
    setIsOpen(isWindowWiderThan('tablet')) // * == `lg` screen
  }, [])

  return (
    <aside
      className={cn('transition-basic overflow-hidden border-r border-gray-lightest bg-off-white', {
        'w-[225px]': isOpen,
        'w-[54px]': !isOpen,
      })}
    >
      {/* // TODO >> Add disabled classes (module: blur-2 opacity-50 pointer-events-none) */}
      <nav className="transition-basic flex h-full min-w-0 flex-col p-3">
        <NavDrawerContext.Provider value={{ isOpen }}>
          {/* // TODO >> A11y HTML semantics (ul, li, etc) */}
          {/* <NavItem href="/" label="Dashboard" icon={Dashboard}></NavItem> */}
          <NavItem href="/income-statement" label="Income Statement" icon={File}></NavItem>
          <NavItem href="/rack-and-stack" label="Rack & Stack" icon={RiskPulseMonitor}></NavItem>
        </NavDrawerContext.Provider>
        {/* // TODO >> Use ButtonV2 */}
        <Button
          withIcon
          onClick={() => setIsOpen((prev) => !prev)}
          buttonType="ghost"
          size="small"
          className="mt-auto w-max rounded-xl bg-gray-lightest px-3 text-gray-dark"
        >
          {/* // TODO >> Use CSS for this... */}
          {isOpen ? <ChevronsLeft /> : <ChevronsRight />}
        </Button>
      </nav>
    </aside>
  )
}
