import { useState, useEffect, useContext, ReactNode } from "react";
import { ChartContext, ChartDataSourceContext, XScale, YScale } from '../Chart';

export type RandomChartDataSourceProps = {
  start: Date,
  end: Date,
  count: number,
  min?: number,
  max?: number,
  children: ReactNode | ReactNode[]
}

export function RandomChartDataSource (props: RandomChartDataSourceProps) {
  const {
    start,
    end,
    count,
    min = 0,
    max = 100,
    children
  } = props;

  const { registerYScale, unregisterYScale, numericScale } = useContext(ChartContext);

  const [data, setData] = useState<Array<{ date: number, value: number, label: string }>>([]);
  const [yScale, setYScale] = useState<YScale>();
  const [xScale, setXScale] = useState<XScale>();

  useEffect(() => {
    const range = +end - +start;
    const interval = range / count;
    const newData = [];

    for (let date = +start; date <= +end; date += interval) {
      const range = max - min;
      const value = min + Math.round(Math.random() * range);
      // random string
      const label = Math.random().toString(36).substring(2, 15);

      newData.push({
        date,
        value,
        label
      });
    }

    setData(newData);

    setYScale({
      min,
      max
    });

    setXScale({
      min: +start,
      max: +end
    });
  }, [start.toISOString(), end.toISOString()]);

  useEffect(() => {
    if (!yScale) return;
    registerYScale(yScale);
    return () => unregisterYScale(yScale);
  }, [yScale]);

  const contextValue = {
    data: data,
    tooltipLabel: `Random Data`,
    tooltipValueLabel: `Value: `,
    valueScale: numericScale,
    timeScale: xScale
  };

  return (
    <ChartDataSourceContext.Provider value={contextValue}>
      {children}
    </ChartDataSourceContext.Provider>
  );
};
