import { useContext, useRef, useEffect } from 'react';
import { ToolTip } from '../ToolTip';
import { ChartContext } from '../Chart';
import { HeatMapContext } from './';
import { translateColorToSafeString } from '../../helpers';

type HeatMapTileType = {
  start: number,
  end: number,
  tooltip: boolean,
  tooltipLabel: string,
  segmentId: string,
  tileIndex: number,
  width: number,
  height: number,
  x: number,
  y: number,
  fill: string
}

export function HeatMapTile(props: HeatMapTileType) {
  const {
    segmentId,
    tileIndex,
    start,
    end,
    tooltipLabel,
    tooltip,
    width,
    height,
    x,
    y,
    fill
  } = props;

  const { chartWidth, chartHeight, chartPadTop, chartPadLeft, isCanvas, canvasRef, canvasRefreshCounter } = useContext(ChartContext);
  const { registerTileLabel, unregisterTileLabel, getFormattedTileLabel } = useContext(HeatMapContext);

  const tile = useRef<SVGRectElement>(null);

  const tooltipContents = getFormattedTileLabel(tileIndex);
  useEffect(() => {
    if (tooltip) registerTileLabel(segmentId, tileIndex, start, end, tooltipLabel);
    return () => unregisterTileLabel(segmentId, tileIndex);
  }, [start, end, tooltipLabel, tooltip]);

  useEffect(() => {
    if (!isCanvas || !canvasRef?.current) return;
    const ctx = canvasRef.current.getContext(`2d`);
    if (!ctx) return;
    const dpr = window.devicePixelRatio || 1;
    ctx.beginPath();
    ctx.fillStyle = translateColorToSafeString(fill, canvasRef.current);
    ctx.fillRect(
      (chartPadLeft + x) * dpr,
      (chartPadTop + y) * dpr,
      width * dpr,
      height * dpr
    );
    ctx.stroke();
  }, [isCanvas, canvasRef, canvasRefreshCounter, chartWidth, chartHeight, chartPadTop, chartPadLeft, fill, x, y]);
  if (isCanvas) return null;

  return (
    <>
      <rect
        ref={tile}
        width={width}
        height={height}
        x={chartPadLeft + x}
        y={chartPadTop + y}
        fill={fill} />
      {(tooltipContents && tooltipContents.length > 0) ? (
        <ToolTip
          wrapperSize={300}
          position="bottom"
          anchor={tile}
          delay={25}>
          {tooltipContents}
        </ToolTip>
      ) : null}
    </>
  );
};

