import { createContext, ReactNode } from 'react';

type HeatMapContextType = {
  tileWidth: number;
  tileHeight: number;
  tilePadding: number;
  numberOfTiles: number;
  numberOfTilesPerRow: number;
  registerTileLabel: (segmentId: string, tileIndex: number, start: number, end: number, label: string) => void;
  unregisterTileLabel: (segmentId: string, tileIndex: number) => void;
  getFormattedTileLabel: (tileIndex: number) => Array<ReactNode | null>;
}

export const HeatMapContext = createContext<HeatMapContextType>({ 
  tileWidth: 0, 
  tileHeight: 0, 
  tilePadding: 0, 
  numberOfTiles: 0, 
  numberOfTilesPerRow: 0,
  registerTileLabel: () => null,
  unregisterTileLabel: () => null,
  getFormattedTileLabel: () => []
});
