
export function translateColorToSafeString (colorString: string, chartNode = document.body): string {
  let value = colorString;
  if (colorString.startsWith('var(')) {
    const match = colorString.match(/--[^)]+/);
    if (match) {
      const [cssVariable] = match;
      value = getComputedStyle(chartNode).getPropertyValue(cssVariable);
    }
  }
  if (value == 'none' || !value) return 'transparent';
  return value;
};
