import moduleStyles from './FunnelGraph.module.scss';

type FunnelGraphCategory = {
  label: string;
  amount: number
}

export type FunnelGraphProps = {
  className?: string;
  categories: FunnelGraphCategory[];
  children: never;
}

export function FunnelGraph (props: FunnelGraphProps) {
  const { className, categories } = props;

  const total = categories.reduce((total, { amount }) => total + amount, 0);

  const getPercentByCategoryIndex = (index: number) => {
    const totalAfter = categories.slice(index).reduce((total, { amount }) => total + amount, 0);
    const value = totalAfter / total;
    return `${ Math.round(value * 100) }%`;
  };

  return (
    <div className={ className }>
      {
        categories.map(({ label }, index) => (
          <div 
            key={ index }
            className={ moduleStyles['funnel-category'] }>
            <span className={ moduleStyles['category-label'] }>{ label }</span>
            <div className={ moduleStyles['value-wrapper'] }>
              <div 
                className={ moduleStyles['value'] }
                style={ { 
                  width: getPercentByCategoryIndex(index),
                  backgroundColor: `rgba(51, 94, 238, ${ getPercentByCategoryIndex(index) })`
                } } />
            </div>
            <span className={ moduleStyles['percent'] }>
              { getPercentByCategoryIndex(index) }
            </span>
          </div>
        ))
      }
    </div>
  );
};
