import { Fragment, ReactNode, useCallback, useContext, useRef, useState } from 'react'
import { HeatMapContext } from './'
import { ChartContext } from '../Chart'
import { formatDateNumeric, formatDateTimeOnly, formatFriendlyDate } from '../../helpers'

export type HeatMapProviderProps = {
  tileSize?: number
  count?: number
  tilePadding?: number
  children?: ReactNode | ReactNode[]
}

type TileLabel = {
  [segmentId: string]: {
    start: number
    end: number
    label: string
  }
}

type FormattedTileLabel = {
  labelElement: JSX.Element
  spanContents: string[]
}

export function HeatMapProvider(props: HeatMapProviderProps) {
  const { tileSize, count: numberOfTilesFromProps = 0, tilePadding = 1, children } = props

  const { chartWidth, chartHeight } = useContext(ChartContext)
  const tileLabels = useRef<TileLabel[]>([])
  const [forcedUpdateCounter, setForcedUpdateCounter] = useState(0)

  const forceUpdate = useCallback(() => {
    setForcedUpdateCounter(forcedUpdateCounter + 1)
  }, [setForcedUpdateCounter, forcedUpdateCounter])

  const registerTileLabel = useCallback(
    (segmentId: string, tileIndex: number, start: number, end: number, label: string) => {
      if (!tileLabels.current[tileIndex]) tileLabels.current[tileIndex] = {}
      tileLabels.current[tileIndex][segmentId] = { start, end, label }
      forceUpdate()
    },
    [tileLabels, forceUpdate],
  )

  const unregisterTileLabel = useCallback(
    (segmentId: string, tileIndex: number) => {
      if (!tileLabels.current[tileIndex]) return
      delete tileLabels.current[tileIndex][segmentId]
      if (Object.values(tileLabels.current).length == 0) delete tileLabels.current[tileIndex]
      forceUpdate()
    },
    [tileLabels, forceUpdate],
  )

  const getFormattedTileLabel = useCallback(
    (tileIndex: number) => {
      if (!tileLabels.current[tileIndex]) return []

      const labels = Object.values(tileLabels.current[tileIndex]).reduce<
        Map<string, FormattedTileLabel>
      >((dateMap, { start, end, label }) => {
        const showDate = formatDateNumeric(start) !== formatDateNumeric(end)
        const key = formatDateNumeric(start)
        const labelElement = (
          <>
            <span style={{ whiteSpace: 'nowrap' }}>{formatFriendlyDate(start)}</span>
            {' - '}
            <span style={{ whiteSpace: 'nowrap' }}>
              {showDate ? formatFriendlyDate(end) : formatDateTimeOnly(end)}
            </span>
          </>
        )
        if (!dateMap.has(key)) {
          dateMap.set(key, {
            labelElement,
            spanContents: [label],
          })
        } else {
          const { spanContents: existingSpanContents }: { spanContents: string[] } = dateMap.get(
            key,
          ) || { spanContents: [] }
          // const { spanContents: existingSpanContents } = dateMap.get(key) || { spanContents: [] }

          dateMap.set(key, {
            labelElement,
            spanContents: [...existingSpanContents, label],
          })
        }

        return dateMap
      }, new Map())

      if (labels.size == 0) return []

      return Array.from(labels.keys()).map(key => {
        const formattedLabel = labels.get(key)
        if (!formattedLabel) return null

        return (
          <div key={key}>
            <strong>{formattedLabel.labelElement}</strong>
            <br />
            {formattedLabel.spanContents.map((spanContents, index) => (
              <Fragment key={index}>
                {index !== 0 ? <br /> : null}
                <span style={{ whiteSpace: 'nowrap' }}>{spanContents}</span>
              </Fragment>
            ))}
          </div>
        )
      })
    },
    [tileLabels],
  )

  const getBestCounts = useCallback(
    (numberOfTiles = 12) => {
      const options = []

      for (let countPerRow = 1; countPerRow <= numberOfTiles / 2; countPerRow += 1) {
        const rowCount = Math.ceil(numberOfTiles / countPerRow)
        const tileWidth = (chartWidth - (countPerRow - 1) * tilePadding) / countPerRow
        const tileHeight = (chartHeight - (rowCount - 1) * tilePadding) / rowCount

        options.push({
          rowCount,
          countPerRow,
          delta: Math.abs(tileWidth - tileHeight),
        })
      }

      const [{ rowCount, countPerRow }] = options.sort(
        ({ delta: deltaA }, { delta: deltaB }) => deltaA - deltaB,
      )

      return {
        rowCount,
        countPerRow,
      }
    },
    [chartWidth, chartHeight, tilePadding],
  )

  let numberOfTileRows = 0,
    numberOfTilesPerRow = 0,
    numberOfTiles = numberOfTilesFromProps

  if (!numberOfTiles && tileSize) {
    numberOfTileRows = Math.floor(chartHeight / (tileSize + tilePadding))
    numberOfTilesPerRow = Math.floor(chartWidth / (tileSize + tilePadding))
    numberOfTiles = numberOfTileRows * numberOfTilesPerRow
  } else if (numberOfTiles) {
    const { rowCount, countPerRow } = getBestCounts(numberOfTiles)
    numberOfTileRows = rowCount
    numberOfTilesPerRow = countPerRow
  }

  const tileWidth = (chartWidth - (numberOfTilesPerRow - 1) * tilePadding) / numberOfTilesPerRow
  const tileHeight = (chartHeight - (numberOfTileRows - 1) * tilePadding) / numberOfTileRows

  const contextValue = {
    tileWidth,
    tileHeight,
    tilePadding,
    numberOfTiles,
    numberOfTilesPerRow,
    registerTileLabel,
    unregisterTileLabel,
    getFormattedTileLabel,
  }

  return <HeatMapContext.Provider value={contextValue}>{children}</HeatMapContext.Provider>
}
