interface MediaScreenSizes {
  mobile: number
  tablet: number
  desktop: number
}

export const mediaScreenSizes: MediaScreenSizes = {
  mobile: 600,
  tablet: 900,
  desktop: 1200,
}

export function isWindowWiderThan(screenSize: keyof MediaScreenSizes): boolean {
  if (typeof window === 'undefined') throw new Error('window is undefined')
  return window.innerWidth > mediaScreenSizes[screenSize]
}
