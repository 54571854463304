export type DateLike = Date | number | string;

export function formatDateNumeric(date: DateLike){
  return new Date(date).toLocaleDateString();
};

export function formatFriendlyDate(date: DateLike){
  return new Date(date).toLocaleDateString([], {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit'
  });
};

export function formatDateTimeOnly(date: DateLike){
  return new Date(date).toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit'
  });
};

export function formatDateHourOnly(date: DateLike){
  return new Date(date).toLocaleTimeString([], {
    hour: 'numeric',
  });
};
