'use client'

import React, { useMemo } from 'react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ColDef, GridOptions, ModuleRegistry } from '@ag-grid-community/core'
import { CsvExportModule } from '@ag-grid-community/csv-export'
import { AgGridReact, CustomLoadingOverlayProps } from '@ag-grid-community/react'
import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { SideBarModule } from '@ag-grid-enterprise/side-bar'

import { AGGWrapperContextProvider, ButtonV2, LoadingSpinner } from '@fsg/gui-bits'
import { cn } from '@fsg/utils'

import { AGGridWrapperProps } from '../../types' // REVIEW >> For some reason the alias caused Vercel builds to fail

ModuleRegistry.registerModules([
  AdvancedFilterModule,
  ClientSideRowModelModule,
  MultiFilterModule,
  SetFilterModule,
  ColumnsToolPanelModule,
  CsvExportModule,
  MenuModule,
  SideBarModule,
])

export function AGGridCore<ContextType>({
  className,
  responsiveOptions,
  gridRef,
  header: customHeader,
  inlineAddRow,
  context = { gridRef: null },
  ...props
}: AGGridWrapperProps) {
  const onExportButton = React.useCallback(() => {
    gridRef.current!.api.exportDataAsCsv()
  }, [gridRef])

  const defaultColDef: ColDef = React.useMemo(
    () => ({
      autoHeight: true,
      sortable: true, // REVIEW >> Why is this not having an effect?
      resizable: true,
      // filter: 'agSetColumnFilter',
      editable: false,
      maxWidth: 325,
      // cellDataType: 'text',
      // * Filter tab contents and order determined by `menuTabs` array order.   The valid values are: 'filterMenuTab', 'generalMenuTab' and 'columnsMenuTab'.
      menuTabs: [
        'filterMenuTab',
        // 'generalMenuTab',
        'columnsMenuTab',
      ],
      ...props.defaultColDef,
    }),
    [props.defaultColDef],
  )

  // https://www.ag-grid.com/javascript-data-grid/grid-options/
  const gridOptions: GridOptions = React.useMemo(
    () => ({
      paginationPageSize: 20,
      cacheBlockSize: 20,
      domLayout: 'normal',
      rowSelection: 'multiple' as const,
      sideBar: {
        hiddenByDefault: true,
      },
      overlayNoRowsTemplate: '<span class="ag-overlay-loading-center">No row data to show</span>',
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading...</span>',
      suppressMenuHide: true, // ? Always show column menu, not only on hover
      justifyOptions: {
        autoSizeColumnsOptions: {
          columnLimits: [{ key: 'icon', maxWidth: 50 }],
        },
      },
      ...props, // * columnDefs // * (ColDef<TData> | ColGroupDef<TData>)[] | null;
      // NOTE: Following cannot be overwritten on instantiation ---------
      pagination: true,
      animateRows: true,
      // ----------------------------------------------------------------

      defaultColDef, // * ColDef<TData> A default column definition. Items defined in the actual column definitions get precedence.
    }),
    [defaultColDef, props],
  )

  function renderHeader() {
    if (customHeader) {
      return customHeader
    } else {
      return (
        <>
          <ButtonV2 variant="secondary" size="lg" onClick={onExportButton}>
            Export CSV
          </ButtonV2>
        </>
      )
    }
  }

  const id = `agg-wrapper-${React.useId().replace(/:/g, '')}`

  const licenseKey =
    'Using_this_AG_Grid_Enterprise_key_( AG-042964 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Facility Solutions Group, Inc )_is_granted_a_( Multiple Applications )_Developer_License_for_( 3 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 24 June 2024 )____[v2]_MTcxOTE4MzYwMDAwMA==add60d1200d6e860c439296c3d979842'
  LicenseManager.setLicenseKey(licenseKey)

  const loader = (props: CustomLoadingOverlayProps & { loadingMessage: string }) => {
    return (
      <div className="ag-overlay-loading-center" role="presentation">
        <LoadingSpinner />
        <div aria-live="polite" aria-atomic="true">
          {props.loadingMessage}
        </div>
      </div>
    )
  }

  const loadingOverlayComponent = React.useMemo(() => {
    return loader
  }, [])
  const loadingOverlayComponentParams = React.useMemo(() => {
    return {
      loadingMessage: '',
    }
  }, [])

  const memoizedContext = useMemo(() => {
    return context
  }, [context])

  return (
    <AGGWrapperContextProvider gridRef={gridRef} value={memoizedContext}>
      {/* COMPONENT WRAPPER */}
      <div style={props.style} id={id} className={cn('ag-theme-quartz ag-theme-fsg rounded-lg border-off-white shadow', className)}>
        {/* HEADER */}
        <div className="flex gap-2xl items-center rounded-tl-md rounded-tr-md bg-white p-sm">{renderHeader()}</div>
        {/* SIZING WRAPPER */}
        <div className={props.height ? `${props.height} ${props.gridComponentWrapper ?? ''}` : `h-[600px] ${props.gridComponentWrapper ?? ''}`}>
          <AgGridReact
            ref={gridRef}
            suppressServerSideFullWidthLoadingRow
            loadingOverlayComponent={loadingOverlayComponent}
            loadingOverlayComponentParams={loadingOverlayComponentParams}
            {...gridOptions}
            className="border-off-white "
          />
        </div>
        {/* FOOTER (IF NO PAGINATION PANEL) */}
        {props.suppressPaginationPanel ? (
          <div className="rounded-bl-md rounded-br-md min-h-min border border-t-0 border-solid border-gray-darker-200 bg-white p-sm shadow"></div>
        ) : null}
      </div>
    </AGGWrapperContextProvider>
  )
}
