'use client'
import { forwardRef, useId, useRef, InputHTMLAttributes, useState } from 'react'
import cn from 'classnames/bind'
import { Check } from '@fsg/icons'
import moduleStyles from './CheckboxInput.module.scss'

const cx = cn.bind(moduleStyles)

export type CheckboxInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string
  color?: string
  labelClasses?: string
}

export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(function CheckboxInput(props, _ref) {
  const { className, label, disabled, color = 'default', onChange, checked, labelClasses, ...rest } = props
  const id = useId()

  const ref = useRef(null)

  const inputProps: InputHTMLAttributes<HTMLInputElement> = {
    ...rest,
    id,
    type: 'checkbox',
    disabled,
  }

  const labelClassName = cx('m--checkbox__wrapper', className, labelClasses)

  const classes = {
    checkbox: 'border border-solid rounded-md mr-sm bg-white text-white',
  }

  return (
    <label htmlFor={id} className={labelClassName} ref={ref}>
      <input ref={_ref} {...inputProps} className={cx('m--checkbox__input')} onChange={onChange} />
      <div className={cx('m--checkbox', classes.checkbox, { checked, [color]: color })} aria-hidden={true}>
        <Check />
      </div>
      <span>{label}</span>
    </label>
  )
})
