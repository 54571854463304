import cn from 'classnames';
import { Button, ButtonProps } from '../Button';
import moduleStyles from './IconButton.module.scss';

export type IconButtonProps = Omit<ButtonProps, 'withIcon'>

export function IconButton (props :IconButtonProps) {
  const { className, size, ...rest } = props;

  const iconButtonClassName = cn(moduleStyles['m--icon-button'], className, {
    [moduleStyles['m--icon-button--small']]: size == 'small',
    [moduleStyles['m--icon-button--medium']]: size == 'medium',
    [moduleStyles['m--icon-button--large']]: size == 'large',
  })

  const buttonProps = {
    ...rest,
    size
  };

  return (
    <Button
      {...buttonProps}
      withIcon
      className={iconButtonClassName}>
      {buttonProps.children}
    </Button>
  );
};
