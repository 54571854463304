import { useContext, useEffect, useId } from 'react'
import { BarContext } from './ChartBarContext'
import { ChartDataSourceContext } from '../Chart'
import { InnerBar } from './InnerBar'

type BarProps = {
  tooltips?: boolean
  includeDatumInBar?: (value: string | number) => boolean
  noUnfilled?: boolean
  filledColor?: string
  unfilledColor?: string
}

export function Bar(props: BarProps) {
  const {
    tooltips = false,
    includeDatumInBar = (value: string | number) => Number(value) > 0,
    noUnfilled = false,
    filledColor = 'black',
    unfilledColor = 'transparent',
  } = props

  const { registerBarSegment, unregisterBarSegment, getBarXOffset, getBarYOffset, numberOfBars, barDisplayWidth, barClusterWidth } =
    useContext(BarContext)

  const { data, valueScale, timeScale, mapValue } = useContext(ChartDataSourceContext)
  const id = useId()

  useEffect(() => {
    registerBarSegment(id)
    return () => unregisterBarSegment(id)
  }, [id])

  if (data.length == 0 || !valueScale || !timeScale || barDisplayWidth <= 0) return null

  const { min: minDate, max: maxDate } = timeScale

  const msBarSize = (maxDate - minDate) / numberOfBars
  const bars = []

  for (let barIndex = 0; barIndex < numberOfBars; barIndex += 1) {
    const barStart = minDate + barIndex * msBarSize,
      barEnd = barStart + msBarSize,
      barRange = barEnd - barStart

    let barValue = 0

    const dataBeforeRange = data.filter(({ date }) => barStart > date)

    let valueBeforeRange = 0
    if (dataBeforeRange.length > 0) {
      const lastDatum = dataBeforeRange[dataBeforeRange.length - 1]
      valueBeforeRange = lastDatum.value
    }

    const dataInRange = data.filter(({ date }) => barStart <= date && barEnd > date)

    if (dataInRange.length > 0 && includeDatumInBar(mapValue ? mapValue(valueBeforeRange) : valueBeforeRange)) {
      const firstDatum = dataInRange[0]
      barValue += (firstDatum.date - barStart) / barRange
    }

    barValue += dataInRange.reduce((percent, datum, index) => {
      if (!includeDatumInBar(mapValue ? mapValue(datum.value) : datum.value)) return percent
      let nextDate = barEnd
      const nextDatum = dataInRange[index + 1]
      if (nextDatum) nextDate = nextDatum.date
      return percent + (nextDate - datum.date) / barRange
    }, 0)

    if (dataInRange.length == 0 && includeDatumInBar(mapValue ? mapValue(valueBeforeRange) : valueBeforeRange)) {
      barValue = 1
    }

    bars.push({
      start: barStart,
      end: barEnd,
      value: barValue,
    })
  }

  return (
    <>
      {bars.map(({ start, end, value }, index) => (
        <InnerBar
          key={index}
          id={`${id}-${index}`}
          displayWidth={barDisplayWidth}
          clusterWidth={barClusterWidth}
          start={start}
          end={end}
          value={value}
          filledColor={filledColor}
          unfilledColor={unfilledColor}
          noUnfilled={noUnfilled}
          tooltip={tooltips}
          xOffset={getBarXOffset(id)}
          yOffset={getBarYOffset(id, index, value)}
        />
      ))}
    </>
  )
}
