'use client'
import classNames from 'classnames/bind'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import ReactVirtualizedList, { ListProps } from 'react-virtualized/dist/commonjs/List'
import moduleStyles from './List.module.scss'

const cx = classNames.bind(moduleStyles)

interface Props extends Omit<ListProps, 'height' | 'width'> {
  rowRenderer?: ListProps['rowRenderer']
  title?: string
  footer?: React.ReactNode
}

export function AutoSizeList(props: Props) {
  const { list, rowRenderer } = props
  let rowRendererCallback: ListProps['rowRenderer']

  if (!rowRenderer) {
    if (typeof list[0] !== 'string') throw new Error('List: rowRenderer is required if list is not an array of strings')

    rowRendererCallback = ({ key, index, style }) => {
      return (
        <div key={key} style={style}>
          {list[index]}
        </div>
      )
    }
  } else {
    rowRendererCallback = rowRenderer
  }

  const listProps = {
    ...props,
    rowHeight: props.rowHeight || 20,
    rowRenderer: rowRendererCallback,
    rowCount: list.length,
  }

  return (
    // ! Do not remove the div wrapper. It is required to allow AutoSizer to be nested within flexbox parents.
    // https://github.com/bvaughn/react-virtualized/blob/master/docs/AutoSizer.md
    <div className="border border-solid border-gray-300 rounded-xl bg-white">
      {props.title ? <h2 className="text-2xl py-2 px-3 border-b border-solid border-gray-300 font-semibold">{props.title}</h2> : null}
      <div style={{ minHeight: '200px' }} className={cx('m--auto-sizer')}>
        <AutoSizer>{({ height, width }) => <ReactVirtualizedList {...listProps} height={height} width={width} />}</AutoSizer>
      </div>
      <div className='p-2 border-t border-solid border-gray-300'>{props.footer}</div>
    </div>
  )
}
