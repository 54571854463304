import { useState, useEffect } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { TextInput } from '../TextInput';
import moduleStyles from './HistogramSlider.module.scss';

export type HistogramSliderProps = {
  step?: number;
  values: [number, number];
  minValue?: number;
  maxValue?: number;
  className?: string;
  onChange: (values: [number, number]) => void;
  maxBarHeight?: number;
  minBarHeight?: number;
  chartData: Array<number>;
  barCount?: number;
  logScale?: boolean;
  inputLabel?: string;
}

type BarValue = { start: number, end: number, count: number }

export function HistogramSlider(props:HistogramSliderProps) {

  const { values, step, chartData, barCount = 50, inputLabel = '', maxBarHeight = 50, minBarHeight = 1, logScale } = props;
  let { minValue, maxValue } = props;

  const sortedData = chartData.slice().sort((a, b) => a - b);
  const range = [Math.min(values[0], sortedData[0]), Math.max(values[1], sortedData[sortedData.length - 1])];

  const [min, max] = range;
  if (!minValue) minValue = min;
  if (!maxValue) maxValue = max;

  const logMin = Math.min(...chartData.filter((n) => n));
  const logBase = Math.pow(maxValue / logMin, 1 / barCount);
  const stepSize = step || (maxValue - minValue) / barCount;
  const barWidth = 100 / barCount;

  const [barValues, setBarValues] = useState<BarValue[]>(Array.from({ length: barCount }, () => ({ end: 0, start: 0, count: 0, height: 0 })));
  const [ratio, setRatio] = useState(1);

  useEffect(() => {
    const bars: BarValue[] = [];
    let highestCount = 0;
    for (let i = 0; i < barCount; i++) {
      const bar: BarValue = {
        start: +(i == 0 ? minValue as number : bars[i - 1].end),
        end: +(i == 0 ? minValue as number + stepSize : bars[i - 1].end + stepSize),
        count: 0
      };

      if (logScale) {
        bar.start = logMin * logBase ** i;
        bar.end = logMin * logBase ** (i + 1);
      }

      if (i == barCount - 1) {
        bar.end = maxValue as number;
      }
      bar.count = chartData.filter((x) => x >= bar.start && x < bar.end).length;
      highestCount = Math.max(highestCount, bar.count);
      bars.push(bar);
    }
    setRatio(maxBarHeight / highestCount);
    setBarValues(bars);
  }, []);

  const [cachedDomain] = useState(range);
  let domain = range;
  if (!logScale) domain = cachedDomain;

  const [update, setUpdate] = useState(range);
  const [value, setValue] = useState([values[0], values[1]]);

  const onChangeCallBack = (values: number[]) => {
    let [newMin, newMax] = values;

    if (logScale) {
      newMin = barValues[newMin].start;
      newMax = barValues[newMax].end;
    }

    newMin = parseFloat(newMin.toFixed(2));
    newMax = parseFloat(newMax.toFixed(2));

    setValue([newMin, newMax]);
    setUpdate([newMin, newMax]);
    props.onChange([newMin, newMax]);
  };

  const getBarClass = (bar: BarValue) => {

    if (bar.start >= value[0] && bar.end <= value[1]) {
      return 'active';
    }

    return '';
  };

  const rangeProps = {
    values: value,
    step: stepSize,
    min: minValue,
    max: maxValue,
    onChange: onChangeCallBack,
  };

  const trackedRangeBackgroundOptions = {
    values: value,
    colors: ['#eee', '#ccc', '#eee'],
    min: domain[0],
    max: domain[1],
  };

  if (logScale) {
    rangeProps.min = 0;
    rangeProps.max = barCount - 1;
    rangeProps.step = 1;

    const minBarIndex = Math.max(barValues.findIndex(({ start, end }) => value[0] >= start && value[0] < end), 0);
    const maxBarIndex = Math.max(barValues.findIndex(({ start, end }) => value[1] > start && value[1] <= end), 0);

    rangeProps.values = [
      minBarIndex,
      maxBarIndex
    ];

    trackedRangeBackgroundOptions.values = rangeProps.values;
    trackedRangeBackgroundOptions.min = rangeProps.min;
    trackedRangeBackgroundOptions.max = rangeProps.max;
  }

  return (
    <div className={moduleStyles['histogram-chart']}>
      <div className={moduleStyles['histogram-chart__bars']}>
        {barValues.map((bar, index) => {
          return <div
            key={index}
            style={{
              height: Math.max(minBarHeight, Math.min(maxBarHeight, bar.count * ratio)),
              width: `${barWidth}%`,
            }}
            className={moduleStyles[getBarClass(bar)]}
          >
            <div className={moduleStyles['histogram-chart__bars__bar']} />
          </div>;
        })}
      </div>
      <Range
        {...rangeProps}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '2px',
              display: 'flex',
              width: '100%'
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '2px',
                width: '100%',
                borderRadius: '4px',
                alignSelf: 'center',
                background: getTrackBackground(trackedRangeBackgroundOptions),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '28px',
              width: '28px',
              borderRadius: '50%',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid rgba(206, 209, 215, 1)',
              boxShadow: '0px 1px 5px -1px rgba(0, 0, 0, 0.05)'
            }}
          >
            <div
              style={{
                height: '8px',
                width: '1px',
                margin: '0 1px',
                backgroundColor: isDragged ? '#548BF4' : 'rgba(206, 209, 215, 1)'
              }}
            />
            <div
              style={{
                height: '8px',
                width: '1px',
                margin: '0 1px',
                backgroundColor: isDragged ? '#548BF4' : 'rgba(206, 209, 215, 1)'
              }}
            />
            <div
              style={{
                height: '8px',
                width: '1px',
                margin: '0 1px',
                backgroundColor: isDragged ? '#548BF4' : 'rgba(206, 209, 215, 1)'
              }}
            />
          </div>
        )}
      />
      <div className={moduleStyles['histogram-chart__values']}>
        <TextInput
          type="text"
          data-type="min"
          value={update[0].toLocaleString('en-US')}
          leftLabel={inputLabel}
          onChange={(e) => {
            const value = parseInt(e.target.value.replace(/\D/g, ''), 10);
            if (value >= domain[0] && value <= domain[1]) {
              onChangeCallBack([value, update[1]]);
            }
          }}
        />
        <div className={moduleStyles['histogram-chart__values__separator']}></div>
        <TextInput
          type="text"
          data-type="max"
          value={update[1].toLocaleString('en-US')}
          leftLabel={inputLabel}
          onChange={(e) => {
            const value = parseInt(e.target.value.replace(/\D/g, ''), 10);
            if (value >= domain[0] && value <= domain[1]) {
              onChangeCallBack([update[0], value]);
            }
          }}
        />
      </div>
    </div>);
};
