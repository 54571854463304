'use client'
import { useRef, useState, useEffect } from 'react'
import cn from 'classnames'
import { Button, ButtonProps } from '../Button'
// // import { ToolTip } from '../ToolTip';
import moduleStyles from './CopyStat.module.scss'

export type CopyStatProps = Omit<
  ButtonProps,
  'buttonType' | 'onClick' | 'ref' | 'withIcon' | 'value'
> & {
  label: string
  value: string | number | null
}

export function CopyStat(props: CopyStatProps) {
  const { label, value, className, ...rest } = props

  const [copied, setCopied] = useState(false)
  const button = useRef<HTMLButtonElement>(null)

  const handleButtonClick = () => {
    window.navigator?.clipboard?.writeText(`${value}`)
    setCopied(true)
  }

  useEffect(() => {
    let mounted = true

    const resetTimeout = setTimeout(() => {
      if (!mounted) return
      setCopied(false)
    }, 1000)

    return () => {
      mounted = false
      clearTimeout(resetTimeout)
    }
  }, [copied])

  return (
    <>
      <Button
        {...rest}
        className={cn(moduleStyles['m--copy-stat'], className)}
        ref={button}
        buttonType="secondary"
        onClick={handleButtonClick}
        disabled={value == undefined || value == null || rest.disabled}
      >
        <span>{label}:</span>
        <strong>{value ?? '—'}</strong>
      </Button>
      {/* <ToolTip
        anchor={button}>
        {copied ? 'Copied!' : 'Click to copy'}
      </ToolTip> */}
    </>
  )
}
