import { Children, ReactElement, useEffect, useState } from 'react'
import cn from 'classnames'
import classNames from 'classnames/bind'

import { Button } from '../Button'
import { ButtonGroup } from '../ButtonGroup'
import { Card } from '../Card'
import { Tab } from '../Tab'
import moduleStyles from './Tabs.module.scss'

const cx = classNames.bind(moduleStyles)

export type TabsProps = {
  children: ReactElement[]
  buttonStyle?: string
  buttonGroupStyle?: string
  cardShadow?: boolean
  containerStyle?: string
  cardContainer?: boolean
  activeTab?: number
}

export function Tabs(props: TabsProps) {
  const { children, buttonStyle, containerStyle, cardShadow, buttonGroupStyle, cardContainer = true, activeTab = 0 } = props

  const [activeTabIndex, setActiveTabIndex] = useState(activeTab)

  useEffect(() => {
    Children.forEach(children, (child, index) => {
      if (child.type !== Tab) {
        throw new Error('Tabs can only have Tab children')
      }
      if (child.props.active) {
        setActiveTabIndex(index)
      }
    })
  }, [children])

  useEffect(() => setActiveTabIndex(activeTab), [activeTab])

  return (
    <>
      <div className={moduleStyles['tabs']}>
        <ButtonGroup className={cx(moduleStyles['tabs-header'], buttonGroupStyle)}>
          {Children.map(children, (child, index) => {
            return (
              <Button
                buttonType={index === activeTabIndex ? 'primary' : 'secondary'}
                className={cn(moduleStyles['tabs-header-tab'], {
                  [moduleStyles['tabs-header-tab__active']]: index === activeTabIndex,
                })}
                onClick={() => setActiveTabIndex(index)}
              >
                {child.props.label}
              </Button>
            )
          })}
        </ButtonGroup>
        {cardContainer ? (
          <Card shadow={cardShadow} className={containerStyle !== '' ? containerStyle : cx(moduleStyles['tabs-content'])}>
            {Children.map(children, (child, index) => {
              if (index === activeTabIndex) {
                return child
              }
            })}
          </Card>
        ) : (
          <div className={containerStyle}>
            {Children.map(children, (child, index) => {
              if (index === activeTabIndex) {
                return child
              }
            })}
          </div>
        )}
      </div>
    </>
  )
}
