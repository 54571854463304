import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
  arcColors?: {
    low?: string
    med?: string
    high?: string
  }
}

const SvgArcMeter = ({ title, titleId, arcColors = {}, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg width="50px" height="50px" viewBox="0 0 40 79" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M.973 72.211c18.075 0 32.727-14.652 32.727-32.727C33.7 21.41 19.048 6.758.973 6.758"
      stroke={arcColors.low || 'var(--yellow)'}
      strokeWidth={12}
    />
    <path d="M26.382 60.258C20.25 67.582 11.022 72.243.7 72.243" stroke={arcColors.med || 'var(--orange)'} strokeWidth={12} />
    <path d="M26.382 18.742C20.25 11.418 11.022 6.758.7 6.758" stroke={arcColors.high || 'var(--cyan)'} strokeWidth={12} />
  </svg>
)
export default SvgArcMeter
