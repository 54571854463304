import { useRef, useContext, ReactNode } from 'react';
import { ToolTip } from '../ToolTip';
import { ChartContext } from '../Chart';
import moduleStyles from './ChartPoint.module.scss';

type ChartPointProps = {
  left: number;
  top: number;
  label: string | ReactNode;
  color?: string;
}

export function ChartPoint({ left, top, label, color }: ChartPointProps) {
  const circle = useRef<SVGCircleElement>(null);

  const { chartWidth, chartHeight, chartPadTop, chartPadLeft } = useContext(ChartContext);

  return (
    <>
      <circle
        className={moduleStyles['point']}
        fill={color || 'rgba(0,0,0,0)'}
        stroke="#FFFFFF"
        strokeWidth={3}
        ref={circle}
        cx={(left * chartWidth) + chartPadLeft}
        cy={(top * chartHeight) + chartPadTop}
        r={8} />
      <ToolTip
        position="bottom"
        anchor={circle}
        delay={25}
        paddingBetweenAnchorAndTooltip={10}>
        {label}
      </ToolTip>
    </>
  );
};
