import { useContext, useEffect } from "react";
import { RadarChartValue, RadarChartContext } from '../RadarChart';
import { RadarChartPoint } from "./";
import { translateColorToSafeString } from '../../helpers';

type RadarDataProps = {
  value: RadarChartValue[];
  stroke: string;
  fill: string;
  tooltips?: boolean;
  label?: string;
}

export function RadarData(props: RadarDataProps) {
  const {
    value,
    stroke,
    fill,
    tooltips,
    label
  } = props;

  const { categories, getValuePointPosition, isCanvas, canvasRef, chartWidth, chartHeight } = useContext(RadarChartContext);

  const points = categories.map(({ id: category, label }) => {
    const cValue = value.find((value) => value.category == category) ?? { category, value: 0 };

    return {
      ...getValuePointPosition(cValue),
      label: `${label}: ${cValue.value.toFixed(2)}`
    };
  });

  useEffect(() => {
    if (!isCanvas || !canvasRef?.current) return;

    const ctx = canvasRef.current.getContext('2d');
    const dpr = window.devicePixelRatio || 1;

    if (!ctx) return;
    ctx.beginPath();
    ctx.lineWidth = 2 * dpr;
    ctx.moveTo(points[0].x, points[0].y);

    for (const { x, y } of points) {
      ctx.lineTo(x, y);
    }

    ctx.closePath();
    ctx.fillStyle = translateColorToSafeString(fill, canvasRef.current);
    ctx.fill();
    ctx.strokeStyle = translateColorToSafeString(stroke, canvasRef.current);
    ctx.stroke();
  }, [isCanvas, canvasRef, chartWidth, chartHeight, value, stroke, fill]);

  if (isCanvas) return null;

  return (
    <g>
      <polygon
        points={points.map(({ x, y }) => `${x},${y}`).join(' ')}
        strokeWidth="2"
        style={{ pointerEvents: 'none' }}
        stroke={stroke}
        fill={fill} />
      {tooltips ? (
        points.map(({ x, y, label: pointLabel }, index) => (
          <RadarChartPoint
            key={index}
            x={x}
            y={y}
            fill={stroke}
            label={label}
            value={pointLabel} />
        ))
      ) : null }
    </g>
  );
};
