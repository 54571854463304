import { ReactNode } from 'react';
import { Range } from 'react-range';
import { IRenderThumbParams } from 'react-range/lib/types';
import cn from 'classnames';
import moduleStyles from './Slider.module.scss';

export type SliderProps = {
  step?: number;
  values: number[];
  minValue?: number;
  maxValue?: number;
  className?: string;
  trackClassName?: string;
  thumbClassName?: string;
  onChange: (values: number[]) => void;
  thumbProps?: object;
  thumbContent?: (props: IRenderThumbParams) => ReactNode;
}

export function Slider(props: SliderProps) {

  const { values, step, onChange, minValue, maxValue, thumbContent, thumbProps, className, trackClassName, thumbClassName } = props;

  const rangeProps = {
    values,
    step,
    min: minValue,
    max: maxValue,
    onChange
  };

  return (
    <div className={cn(moduleStyles['slider'], className)}>
      <Range
        {...rangeProps}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className={cn(moduleStyles['slider__track'], trackClassName)}
            style={{
              ...props.style,
              height: '2px',
              display: 'flex',
              width: '100%'
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '2px',
                width: '100%',
                borderRadius: '4px',
                alignSelf: 'center',
                background: '#eee'
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged, value }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '28px',
              width: '28px',
              borderRadius: '50%',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid rgba(206, 209, 215, 1)',
              boxShadow: '0px 1px 5px -1px rgba(0, 0, 0, 0.05)'
            }}
            {...thumbProps}
            className={thumbClassName}
          >
            {thumbContent ? (
              thumbContent({ props, isDragged, value } as IRenderThumbParams)
            ) : (
              <>
                <div
                  style={{
                    height: '8px',
                    width: '1px',
                    margin: '0 1px',
                    backgroundColor: isDragged ? '#548BF4' : 'rgba(206, 209, 215, 1)'
                  }}
                />
                <div
                  style={{
                    height: '8px',
                    width: '1px',
                    margin: '0 1px',
                    backgroundColor: isDragged ? '#548BF4' : 'rgba(206, 209, 215, 1)'
                  }}
                />
                <div
                  style={{
                    height: '8px',
                    width: '1px',
                    margin: '0 1px',
                    backgroundColor: isDragged ? '#548BF4' : 'rgba(206, 209, 215, 1)'
                  }}
                />
              </>
            )}
          </div>
        )}
      />
    </div>);
};
