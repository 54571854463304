'use client'
import { useRef, useState, useEffect, forwardRef } from 'react'
import cn from 'classnames/bind'
import { Button, ButtonProps } from '../Button'
// import { ToolTip } from '../ToolTip'
import moduleStyles from './CopyIcon.module.scss'

export type CopyIconProps = Omit<ButtonProps, 'buttonType' | 'onClick' | 'ref' | 'value'> & {
  copyText: string
  rounded?: boolean
}

const cx = cn.bind(moduleStyles)

export const CopyIcon = forwardRef<HTMLButtonElement, CopyIconProps>(function CopyIcon(props: CopyIconProps, _ref) {
  const { copyText, rounded, className, ...rest } = props

  const [copied, setCopied] = useState(false)
  const button = useRef<HTMLButtonElement>(null)

  const handleButtonClick = () => {
    window.navigator?.clipboard?.writeText(`${copyText}`)
    setCopied(true)
  }

  useEffect(() => {
    let mounted = true

    const resetTimeout = setTimeout(() => {
      if (!mounted) return
      setCopied(false)
    }, 1000)

    return () => {
      mounted = false
      clearTimeout(resetTimeout)
    }
  }, [copied])

  return (
    <>
      <Button
        {...rest}
        className={cx('m--copy-icon', className, {
          rounded: rounded,
        })}
        ref={button}
        buttonType="secondary"
        onClick={handleButtonClick}
        disabled={rest.disabled}
        withIcon
      >
        {rest.children}
      </Button>
      {/* <ToolTip anchor={button}>{copied ? 'Copied!' : 'Click to copy'}</ToolTip> */}
    </>
  )
})

