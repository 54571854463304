import { FC, forwardRef, InputHTMLAttributes, useId, useRef } from 'react'
import cn from 'classnames'

// import { ToolTip } from '../ToolTip';
import moduleStyles from './ToggleInput.module.scss'

export interface ToggleInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.FC
  className?: string
  label: string
  disabled?: boolean
  disabledReason?: string
  alwaysBlue?: boolean
  reversed?: boolean
  inputRef?: React.Ref<HTMLInputElement> //CODE REIVEW - i dont actually think this is necessary, since we are using a forward ref here too. for now, destructuring it from the props prevents the warning
}

export const ToggleInput = forwardRef<HTMLInputElement, ToggleInputProps>(function ToggleInput(props, _ref) {
  const { icon: Icon, className, label, disabled, disabledReason, alwaysBlue, inputRef, reversed, ...rest } = props

  const labelRef = useRef<HTMLLabelElement>(null)
  const id = useId()

  const inputProps: InputHTMLAttributes<HTMLInputElement> = {
    ...rest,
    id,
    type: 'checkbox',
    disabled,
  }
  if (label === 'Required' || label === 'TESTING') {
  }

  const labelClassName = cn(moduleStyles['toggle-input'], className, {
    [moduleStyles['toggle-input--disabled']]: disabled,
    [moduleStyles['toggle-input--reversed']]: reversed,
  })

  return (
    <>
      <label htmlFor={id} ref={labelRef} className={labelClassName}>
        <input ref={_ref} {...inputProps} />
        <span
          className={cn(moduleStyles['toggle-input-toggler'], {
            [moduleStyles['toggle-input-toggler--always-blue']]: alwaysBlue,
          })}
        />
        {Icon ? <Icon /> : null}
        <span>{label}</span>
      </label>
      {/* {inputProps.disabled && disabledReason ? (
        <ToolTip delay={100} anchor={labelRef}>
          {disabledReason}
        </ToolTip>
      ) : null} */}
    </>
  )
})
