import { useContext, forwardRef, useId, useEffect } from 'react';
import { ChartContext, ChartDataSourceContext } from '../Chart';
import { ChartPoint } from '../ChartPoint';
import { formatFriendlyDate, translateColorToSafeString } from '../../helpers';

type GradientPolygonProps = {
  tooltips?: boolean,
  tooltipValueFormat?: (value?: string | number) => string,
  gradientStart?: string,
  gradientEnd?: string,
  baseline?: number
}

export const GradientPolygon = forwardRef<SVGPolygonElement, GradientPolygonProps>(
  function GradientPolygon(props, _ref) {
    const {
      tooltips = false,
      tooltipValueFormat = (n) => n,
      gradientStart = '#000000',
      gradientEnd = '#000000',
      baseline = 0,
      ...rest
    } = props;

    const { chartWidth, chartHeight, chartPadTop, chartPadLeft, isCanvas, canvasRef, canvasRefreshCounter } = useContext(ChartContext);
    const { data, tooltipLabel, tooltipValueLabel, valueScale, timeScale, mapValue } = useContext(ChartDataSourceContext);
    const id = useId();

    useEffect(() => {
      if (!isCanvas || !canvasRef?.current) return;
      if (data.length == 0 || !valueScale || !timeScale) return;

      const { min: minDate, max: maxDate } = timeScale;
      const { min: minValue, max: maxValue } = valueScale;

      if (minValue == Infinity || maxValue == -Infinity) return;

      let positionedData = data.map(({ value, date }) => ({
        top: minValue == maxValue ? 1 : 1 - ((value - minValue) / (maxValue - minValue)),
        left: (date - minDate) / (maxDate - minDate),
        friendlyDate: formatFriendlyDate(date),
        friendlyValue: tooltipValueFormat(mapValue ? mapValue(value) : value)
      }));

      if (baseline > 0) {
        positionedData = positionedData.map((datum) => {
          const totalHeight = 1 - baseline;

          return {
            ...datum,
            top: datum.top * totalHeight
          };
        });
      }

      const ctx = canvasRef.current.getContext('2d');
      const dpr = window.devicePixelRatio || 1;

      if (!ctx) return;

      const gradient = ctx.createLinearGradient(0, 0, chartWidth * dpr, chartHeight * dpr);
      gradient.addColorStop(0.05, translateColorToSafeString(gradientStart, canvasRef.current));
      gradient.addColorStop(0.95, translateColorToSafeString(gradientEnd, canvasRef.current));

      ctx.fillStyle = gradient;

      ctx.beginPath();

      let moved = false;

      for (const point of [{ top: 1, left: 0 }, ...positionedData, { top: 1, left: 1 }]) {
        const left = (point.left * chartWidth) + chartPadLeft;
        const top = (point.top * chartHeight) + chartPadTop;

        if (!moved) {
          ctx.moveTo(left * dpr, top * dpr);
          moved = true;
        } else {
          ctx.lineTo(left * dpr, top * dpr);
        }
      }

      ctx.closePath();
      ctx.fill();
    }, [isCanvas, canvasRef, canvasRefreshCounter, chartWidth, chartHeight, chartPadTop, chartPadLeft, data, valueScale, timeScale]);

    if (isCanvas) return null;

    if (data.length == 0 || !valueScale || !timeScale) return null;

    const { min: minDate, max: maxDate } = timeScale;
    const { min: minValue, max: maxValue } = valueScale;

    if (minValue == Infinity || maxValue == -Infinity) return null;

    let positionedData = data.map(({ value, date }) => ({
      top: minValue == maxValue ? 1 : 1 - ((value - minValue) / (maxValue - minValue)),
      left: (date - minDate) / (maxDate - minDate),
      friendlyDate: formatFriendlyDate(date),
      friendlyValue: tooltipValueFormat(mapValue ? mapValue(value) : value)
    }));

    if (baseline > 0) {
      positionedData = positionedData.map((datum) => {
        const totalHeight = 1 - baseline;

        return {
          ...datum,
          top: datum.top * totalHeight
        };
      });
    }

    const polygonPoints = [{ top: 1, left: 0 }, ...positionedData, { top: 1, left: 1 }].map((point) => {
      return `${(point.left * chartWidth) + chartPadLeft},${(point.top * chartHeight) + chartPadTop}`;
    }).join(' ');

    return (
      <>
        <defs>
          <linearGradient id={`gradient-${id}`} gradientTransform="rotate(-45)">
            <stop offset="5%" stopColor={gradientStart} />
            <stop offset="95%" stopColor={gradientEnd} />
          </linearGradient>
        </defs>
        <polygon
          ref={_ref}
          {...rest}
          fill={`url(#gradient-${id})`}
          stroke="none"
          points={polygonPoints} />
        {tooltips ? (
          positionedData.map(({ top, left, friendlyDate, friendlyValue }, index) => (
            <ChartPoint
              key={index}
              top={top}
              left={left}
              color={gradientStart}
              label={(
                <>
                  <strong>{tooltipLabel}</strong><br />
                  <span>{tooltipValueLabel}{friendlyValue}</span><br />
                  <span>{friendlyDate}</span><br />
                </>
              )} />
          ))) : null
        }
      </>
    );
  });
