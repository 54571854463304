import cn from 'classnames'
import moduleStyles from './ArcMeter.module.scss'
import { ArcMeter as ArcMeterIcon } from '@fsg/svg'

type ArcMeterProps = {
  value: number
  className?: string
  size?: 'small' | 'medium' | 'large' | number
  min?: number
  max?: number
  angleRange?: { min: number; max: number }
  indicatorColor?: string
  indicatorSize?: number
  arcColors?: {
    low?: string
    med?: string
    high?: string
  }
  style?: React.CSSProperties
  indicatorStyle?: React.CSSProperties
  rotation?: number
}

export function ArcMeter(props: ArcMeterProps) {
  const {
    value: valueFromProps,
    className,
    max = 100,
    min = 0,
    angleRange = { min: 190, max: 350 },
    indicatorColor = 'var(--white)',
    indicatorSize: customIndicatorSize,
    arcColors = {},
    style,
    size = 'medium', // default size
    indicatorStyle,
    rotation,
  } = props

  let RADIUS, INDICATOR_SIZE

  // Customize the size
  switch (size) {
    case 'small':
      RADIUS = 25
      INDICATOR_SIZE = 4
      break
    case 'medium':
      RADIUS = 40
      INDICATOR_SIZE = 7
      break
    case 'large':
      RADIUS = 60
      INDICATOR_SIZE = 10
      break
    default:
      if (typeof size === 'number') {
        RADIUS = size
        INDICATOR_SIZE = size / 6
      } else {
        RADIUS = 40
        INDICATOR_SIZE = 7
      }
  }

  let value = valueFromProps ?? 0
  if (value < min) value = min
  if (value > max) value = max

  const { min: angleMin, max: angleMax } = angleRange
  const percent = (value - min) / (max - min)
  const angle = percent * (angleMax - angleMin) + angleMin

  const radians = angle * (Math.PI / 180)
  const inner_radius = RADIUS - INDICATOR_SIZE

  const x = inner_radius * -Math.sin(radians)
  const y = inner_radius * -Math.cos(radians)

  const top = y - INDICATOR_SIZE / 2 + inner_radius + INDICATOR_SIZE
  const left = x - INDICATOR_SIZE / 2

  const combinedIndicatorStyle = {
    top,
    left,
    width: INDICATOR_SIZE,
    height: INDICATOR_SIZE,
    background: indicatorColor,
    ...indicatorStyle,
  }

  const meterStyle = {
    transform: `rotate(${rotation || 0}deg)`, // Apply rotation
    ...style,
  }

  return (
    <div className={cn(moduleStyles['arc-meter'], className)} style={meterStyle}>
      <ArcMeterIcon width={RADIUS} height={RADIUS * 2} arcColors={arcColors} />
      <i key={angle} className={moduleStyles['indicator']} style={combinedIndicatorStyle} />
    </div>
  )
}
