import React from 'react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ModuleRegistry } from '@ag-grid-community/core'

import { AGGridCore } from '@fsg/gui-bits'

import { AGGridWrapperProps } from '../../types' // REVIEW >> For some reason the alias caused Vercel builds to fail // REVIEW >> For some reason the alias caused Vercel builds to fail

ModuleRegistry.registerModules([ClientSideRowModelModule])

export function AGGridClientSide<ContextType = any>(props: AGGridWrapperProps) {
  return <AGGridCore<ContextType> {...props} />
}
