function isDateString(str: string) {
  // * Limited to YYYY-MM-DD and MM/DD/YYYY
  const regex = /^\d{4}-\d{2}-\d{2}$|^\d{2}\/\d{2}\/\d{2,4}$/
  return regex.test(str)
}

export function getFormattedDate(value: string, format: string = 'YYYY/MM/DD') {
  if (isDateString(value)) {
    const date = new Date(value)
    switch (format) {
      case 'YYYY/MM/DD':
        return date.toISOString().slice(0, 10).replace(/-/g, ' / ')
      case 'MM/DD/YYYY':
        return date.toLocaleDateString('en-US', {
          timeZone: 'UTC',
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        })
      case 'long':
        return date.toLocaleDateString('en-US', {
          timeZone: 'UTC',
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })
      case 'YYYY-MM-DD':
      default:
        return date.toISOString().slice(0, 10)
    }
  } else {
    console.warn(`Supplied value is not a date string: ${value}`)
    return value
  }
}

export function stripPx(value: string) {
  return parseInt(value, 10)
}

export function truncateString(str: string, maxChars: number): string {
  if (typeof str !== 'string' || maxChars < 0) {
    throw new Error('Invalid string')
  }
  if (typeof maxChars !== 'number') {
    throw new Error('Invalid number')
  }

  if (str.length <= maxChars) {
    return str
  }

  return `${str.substring(0, maxChars)}...`
}

// export function getDataGridCellString(value: string | number) {
//   if (isDateString(String(value))) {
//     return getFormattedDate(String(value))
//   } else {
//     return Math.round(Number(value)).toLocaleString('en-US')
//   }
// }
