'use client'

import Image from 'next/image'

import { cn } from '@fsg/utils'

import PageNotFound from '../assets/PageNotFound.png'

type Props = {
  className?: string
}
export function NotFoundCloud404({ className }: Props) {
  return <Image className={cn('w-[64px]', className)} src={PageNotFound} alt="404 - Page not found" width={60} height={60} loading="eager" priority />
}
