import { PropsWithChildren, ReactNode } from 'react'
import moduleStyles from './Fallback.module.scss'
import cn from 'classnames/bind'

const cx = cn.bind(moduleStyles)

// : React.FC<FallbackProps>
interface Props extends PropsWithChildren {
  header: string
}

export function Fallback({ header, children }: PropsWithChildren<Props>) {
  return (
    <div className={cx('m--fallback', 'text-gray-darker-400')}>
      <div>
        <p className={cx('m--header', 'heading-lg')}>{header}</p>
        {children}
      </div>
    </div>
  )
}
