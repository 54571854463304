'use client'

import React, { RefObject, useMemo } from 'react'
import { AgGridReact } from '@ag-grid-community/react'

export type RowCount = number | null

type BaseContextType = {
  modalIsOpen: boolean
  setModalIsOpen: (value: boolean) => void
}

type ProviderProps<C> = {
  value: C & BaseContextType
  gridRef: RefObject<AgGridReact<any>>
}

// type BaseContextType = AGGridContextProps & {
//   gridRef: RefObject<AgGridReact<any>>
// }

const AGGWrapperContext = React.createContext<any | undefined>(undefined)

export function AGGWrapperContextProvider(props: React.PropsWithChildren<ProviderProps<any>>) {
  const value = useMemo(() => {
    return {
      ...props.value,
      gridRef: props.gridRef,
      myGridManager: props.value.myGridManager ?? null,
      allGridManager: props.value.allGridManager ?? null,
    }
  }, [props.gridRef, props.value])

  return <AGGWrapperContext.Provider value={value}>{props.children}</AGGWrapperContext.Provider>
}

export function useAGGWrapperContext<C = any>() {
  const context = React.useContext<C & BaseContextType>(AGGWrapperContext)

  if (context === undefined) throw new Error('Cannot call useAGGWrapperContext outside of AGGWrapperContextProvider')

  return context
}
