import { Label, NameBubble } from '@fsg/gui-bits'
// import { getServerSession } from '@fsg/next-auth/session'
import { Logo } from '@fsg/svg'

type Props = {
  label?: string
  session: any // Note: temp fix (see below)
}

export function Header({ label, session }: Props) {
  // TODO >> Figure out why @fsg/next-auth/session isn't importing and use it here
  // const session = await getServerSession()
  return (
    <div className="relative flex items-center justify-between bg-white px-lg shadow-md">
      <div className="flex items-center gap-lg">
        <Logo />
        <Label>{label}</Label>
      </div>
      <div>
        <NameBubble name={session?.user.name} />
      </div>
    </div>
  )
}
