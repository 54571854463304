import { useContext, forwardRef } from 'react';
import { ChartContext, ChartDataSourceContext, } from '../Chart';
import { ChartPoint } from '../ChartPoint';
import { formatFriendlyDate } from '../../helpers';
import moduleStyles from './Scatter.module.scss';

type ScatterProps = {
  stroke?: string;
  fill?: string;
  radius?: number;
  tooltips?: boolean;
  tooltipValueFormat?: (value: number | string) => string;
  draw?: boolean;
  baseline?: number;
  strokeDasharray?: string;
}

export const Scatter = forwardRef<SVGPolylineElement, ScatterProps>(
  function Scatter(props: ScatterProps, _ref) {
    const {
      tooltips = false,
      tooltipValueFormat = (n) => n,
      draw = false,
      stroke = `rgba(255,0,0,0.75)`,
      fill = `rgba(255,0,0,0.5)`,
      radius = 3,
      baseline = 0,
      ...rest
    } = props;

    const { chartWidth, chartHeight, chartPadTop, chartPadLeft } = useContext(ChartContext);
    const { data, tooltipLabel, tooltipValueLabel, valueScale, timeScale, mapValue } = useContext(ChartDataSourceContext);

    if (data.length == 0 || !valueScale || !timeScale) return null;

    const { min: minDate, max: maxDate } = timeScale;
    const { min: minValue, max: maxValue } = valueScale;

    if (minValue == Infinity || maxValue == -Infinity) return null;

    let positionedData: any = data.map(({ value, date, label }) => ({
      top: minValue == maxValue ? 1 : 1 - ((value - minValue) / (maxValue - minValue)),
      left: (date - minDate) / (maxDate - minDate),
      friendlyDate: formatFriendlyDate(date),
      friendlyValue: tooltipValueFormat(mapValue ? mapValue(value) : value),
      label
    }));

    if (baseline > 0) {
      positionedData = positionedData.map((datum: { top: number; }) => {
        const totalHeight = 1 - baseline;
        return {
          ...datum,
          top: datum.top * totalHeight
        };
      });
    }

    return (
      <>
        {positionedData.length > 0 && positionedData.map((x: { left: number; top: number; }, index: number) => {
          return <circle key={index} cx={x.left * chartWidth + chartPadLeft} cy={x.top * chartHeight + chartPadTop} r={radius * 2} fill={fill} stroke={stroke} />;
        })}
        {tooltips ? (
          positionedData.map(({ top, left, friendlyDate, friendlyValue, label }: any, index: number) => (
            <ChartPoint
              key={index}
              top={top}
              left={left}
              color={stroke}
              label={(
                <>
                  <div>{label && <strong>{label}</strong>}</div>
                  <div><strong>{tooltipLabel}</strong></div>
                  <div><span>{tooltipValueLabel}{friendlyValue}</span></div>
                  <div><span>{friendlyDate}</span></div>
                </>
              )} />
          ))
        ) : null}
      </>
    );
  });
