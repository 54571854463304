import { useState, useEffect, useContext, ReactNode } from 'react';
import { ChartContext, ChartDataSourceContext ,XScale, YScale, DataPoint } from '../Chart';


type PassthroughChartDataSourceProps = {
  data: DataPoint[];
  mapValue?: (value: number) => string;
  tooltipLabel?: string;
  tooltipValueLabel?: string;
  valueLabels?: Map<number, string>;
  children: ReactNode | ReactNode[]
  bufferPercentage?: number;
}

export function PassthroughChartDataSource (props :PassthroughChartDataSourceProps) {
  const {
    data,
    mapValue,
    tooltipLabel,
    tooltipValueLabel,
    valueLabels,
    children,
    bufferPercentage = 0
  } = props;

  const { registerYScale, unregisterYScale, numericScale } = useContext(ChartContext);

  const [yScale, setYScale] = useState<YScale>();
  const [xScale, setXScale] = useState<XScale>();

  useEffect(() => {
    const minValue = data.reduce((minValue, { value }) => value < minValue ? value : minValue, Infinity);
    const maxValue = data.reduce((maxValue, { value }) => value > maxValue ? value : maxValue, -Infinity);
    const minDate = data.reduce((minDate, { date }) => date < minDate ? date : minDate, Infinity);
    const maxDate = data.reduce((maxDate, { date }) => date > maxDate ? date : maxDate, -Infinity);

    setXScale({
      min: minDate,
      max: maxDate
    });

    const buffer = (maxValue - minValue) * (bufferPercentage / 100);

    setYScale({
      min: minValue - buffer,
      max: maxValue + buffer,
      valueLabels
    });
  }, [JSON.stringify(data), setYScale, setXScale]);

  useEffect(() => {
    if (!yScale) return;
    registerYScale(yScale);
    return () => unregisterYScale(yScale);
  }, [yScale]);

  const contextValue = {
    data: data,
    mapValue,
    tooltipLabel,
    tooltipValueLabel,
    valueScale: numericScale,
    timeScale: xScale
  };

  return (
    <ChartDataSourceContext.Provider value={contextValue}>
      {children}
    </ChartDataSourceContext.Provider>
  );
};
