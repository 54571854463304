'use client'

import * as React from 'react'

export function useScrollbarWidth(): number {
  const [scrollbarWidth, setScrollbarWidth] = React.useState<number>(0)

  React.useEffect(() => {
    const outer = document.createElement('div')
    outer.style.visibility = 'hidden'
    outer.style.overflow = 'scroll' // forcing scrollbar to appear
    document.body.appendChild(outer)

    // Get width for container without scrollbar
    const widthNoScroll = outer.offsetWidth

    // force a scroll bar
    outer.style.overflow = 'scroll'

    // Get width for container with scrollbar
    const widthWithScroll = outer.clientWidth

    // Remove temporary element from the body
    document.body.removeChild(outer)

    // Set scrollbar width to state
    setScrollbarWidth(widthNoScroll - widthWithScroll)
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return scrollbarWidth
}
