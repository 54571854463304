const rgbRegExp = /^rgba?\((\d{1,3}),\s?(\d{1,3}),\s?(\d{1,3})/;
const hexRegExp = /^#?[\w\d]{6}$/;

function getRGBFromColorString (color: string) {
  const rgbMatch = color.match(rgbRegExp);

  if (hexRegExp.test(color)) {
    return {
      r: parseInt(color.replace('#', '').substring(0, 2), 16),
      g: parseInt(color.replace('#', '').substring(2, 4), 16),
      b: parseInt(color.replace('#', '').substring(4, 6), 16)
    };
  } else if (rgbMatch) {
    const [, r, g, b] = rgbMatch;
    return {
      r: parseInt(r),
      g: parseInt(g),
      b: parseInt(b)
    };
  } else {
    return {
      r: 0,
      g: 0,
      b: 0
    };
  }
};

export function calculateColorFromPercent(color1: string, color2: string, percent: number) {
  const pad = (n: string) => n.length < 2 ? `0${n}` : n;
  const hex = (n: number) => pad(n.toString(16));

  const { r: r1, g: g1, b: b1 } = getRGBFromColorString(color1);
  const { r: r2, g: g2, b: b2 } = getRGBFromColorString(color2);

  percent = Math.min(percent, 1);
  percent = Math.max(percent, 0);

  let r = Math.round((r1 + r2) / 2);
  let g = Math.round((g1 + g2) / 2);
  let b = Math.round((b1 + b2) / 2);

  let colorsToMix, amountToMix = 0;

  if (percent < 0.5) {
    colorsToMix = { r: r1, g: g1, b: b1 };
    amountToMix = (0.5 - percent) * 2;
  } else if (percent > 0.5) {
    colorsToMix = { r: r2, g: g2, b: b2 };
    amountToMix = (percent - 0.5) * 2;
  }

  if (colorsToMix && amountToMix > 0) {
    const { r: rm, g: gm, b: bm } = colorsToMix;

    r = Math.round((rm * amountToMix) + (r * (1 - amountToMix)));
    g = Math.round((gm * amountToMix) + (g * (1 - amountToMix)));
    b = Math.round((bm * amountToMix) + (b * (1 - amountToMix)));
  }

  return `#${hex(r)}${hex(g)}${hex(b)}`;
};
