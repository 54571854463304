'use client'

import * as React from 'react'

// Note:  Not super useful, but created for particular use and thought it may be the start of something useful in the future

export function useIsGridChild(selector: string): boolean {
  if (!selector) throw new Error('useIsGridChild() requires a selector string as its first argument')
  const [isGridChild, setIsGridChild] = React.useState<boolean>(false)

  React.useEffect(() => {
    const element = document.querySelector(selector) as HTMLElement
    const parent = element.parentElement
    if (element && parent) {
      const parentStyle = getComputedStyle(parent)
      const parentIsGrid = parentStyle.display === 'grid' || parentStyle.display === 'inline-grid'
      setIsGridChild(parentIsGrid)

      // const inlineHeight = parentStyle.height
      // const computedHeight = parentStyle.height
      // const height = parent.offsetHeight
      // console.debug(`useIsGridChild() >> parent inline height: ${inlineHeight}`)
      // console.debug(`useIsGridChild() >> parent computed height: ${computedHeight}`)
      // if (height && height !== 0) console.debug(`useIsGridChild() >> parent height: ${height}`)
      // else console.debug('useIsGridChild() >> parent height is not set')
      // const hasGridTemplateRows = parentStyle.gridTemplateRows !== 'none'
      // if (parentIsGrid) {
      //   if (hasGridTemplateRows) console.debug('Parent is a grid container with grid-template-rows:', parentStyle.gridTemplateRows)
      //   else console.debug('Parent is a grid container without explicitly set grid-template-rows')
      // }
    } else {
      console.warn(`useIsGridChild() >> could not find an element matching the selector: ${selector}`)
    }
  }, [selector])

  return isGridChild
}

// function useQuerySelector<TData = unknown, TError = unknown, TTargetData = unknown>(
//   queryResult: UseQueryResult<TData, TError>,
//   selector: (v: TData) => TTargetData,
//   deps: unknown[] = []
// ): UseQueryResult<TTargetData, TError>
