import { forwardRef, HTMLAttributes, Ref } from 'react'
import cn from 'classnames/bind'

import { LoadingSpinner } from '../LoadingSpinner'
import moduleStyles from './Card.module.scss'

export type CardProps = HTMLAttributes<HTMLDivElement> & {
  // ref?: Ref<HTMLDivElement> // REVIEW >> forwardRef provides its own ref
  loading?: boolean
  shadow?: boolean
}

const cx = cn.bind(moduleStyles)

// export const Card = forwardRef<HTMLDivElement, CardProps>(function Card(props: CardProps, ref) {
function CardComponent(props: CardProps, ref: Ref<HTMLDivElement>) {
  const { className, children, loading, shadow = true, ...rest } = props

  // REVIEW >> @Dan @Chris - check your prettier/eslint settings.  Make sure you're preferring single quotes (backticks only for string literals)
  // REVIEW >> if you're using bind, you don't need the `moduleStyles['m--card']` syntax
  const divClassName = cx(moduleStyles['m--card'], className, {
    ['m--card--loading']: !!loading,
    ['card-shadow']: shadow,
  })

  return (
    <div {...rest} ref={ref} className={divClassName}>
      {loading ? (
        <div className="loading-overlay">
          <LoadingSpinner size="small" />
        </div>
      ) : null}
      {children}
    </div>
  )
}

export const Card = forwardRef<HTMLDivElement, CardProps>(CardComponent)
