import { FC, forwardRef, useRef, useId, InputHTMLAttributes } from 'react'
import cn from 'classnames'
// import { ToolTip } from '../ToolTip';
import { Radio } from '@fsg/icons'
import moduleStyles from './RadioInput.module.scss'

export type RadioInputProps = InputHTMLAttributes<HTMLInputElement> & {
  icon?: FC
  className?: string
  label?: string
  name: string
  disable?: boolean
  disabledReason?: string
  checked?: boolean
}

export const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(function RadioInput(
  props: RadioInputProps,
  _ref,
) {
  const { icon: Icon, className, label, name, disabled, disabledReason, checked, ...rest } = props

  const id = useId()

  const inputProps: InputHTMLAttributes<HTMLInputElement> = {
    ...rest,
    id,
    type: 'radio',
    disabled,
    name,
    checked,
  }

  const labelClassName = cn(moduleStyles['radio-input'], className, {
    [moduleStyles['radio-input--disabled']]: disabled,
  })

  return (
    <label htmlFor={id} className={labelClassName}>
      <input ref={_ref} {...inputProps} />
      <Radio />
      {/* {disabled && disabledReason ? (
        <ToolTip delay={100} anchor={ref}>
          {disabledReason}
        </ToolTip>
      ) : null} */}
      {Icon ? <Icon /> : null}
      {label !== undefined && <span>{label}</span>}
    </label>
  )
})
