import cn from 'classnames'
import moduleStyles from './NumberText.module.scss'
import { parseNumberFromString } from '../../../fsg-utils/src'

export type NumberTextProps = {
  className?: string
  children: string
  decimals?: number
  commas?: boolean
  percent?: boolean
  bubble?: boolean
  color?: (value: number) => string
  totalLength?: number
  numberFormatOptions?: Intl.NumberFormatOptions
}

export function NumberText(props: NumberTextProps) {
  const {
    decimals = 2,
    commas = true,
    color,
    percent = false,
    bubble = false,
    className,
    children,
    numberFormatOptions: numberFormatOptionsParam,
  } = props

  if (!(typeof children == 'string')) throw new Error('NumberText requires a single string child')

  let number: number = parseNumberFromString(children)
  const isNotANumber = isNaN(number)
  let displayNumber: string

  if (isNotANumber) {
    displayNumber = ['null', 'undefined', ''].includes(children) ? '-' : children
    // prefer passed in numberFormatOptions, else use a default config that is based on component props
  } else {
    const numberFormatOptions = numberFormatOptionsParam || {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
      useGrouping: commas,
    }

    const formatter = new Intl.NumberFormat(undefined, numberFormatOptions)
    displayNumber = formatter.format(number)

    // do not display -0.00, which is absurd
    if (Object.is(displayNumber, '-0.00')) {
      displayNumber = '0.00'
    }
  }

  let style: any
  if (color && typeof color == 'function') {
    const key = bubble ? 'backgroundColor' : 'color'
    style = { [key]: color(number) }
    if (bubble) style.color = '#ffffff'
  }

  return (
    <div
      className={cn(moduleStyles['m--number-text'], 'font-regular', className)}
      style={bubble ? {} : style}
    >
      {bubble ? (
        <span className={moduleStyles['m--bubble']} style={style}>
          {displayNumber}
          {percent ? <span className={moduleStyles['m--percent-sign']}>%</span> : ''}
        </span>
      ) : (
        <>
          {displayNumber}
          {percent ? <span className={moduleStyles['m--percent-sign']}>%</span> : ''}
        </>
      )}
    </div>
  )
}
