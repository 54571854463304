import cn from 'classnames/bind'
import moduleStyles from './LoadingSpinner.module.scss'
import { Spinner } from '@fsg/svg'

const cx = cn.bind(moduleStyles)

export type LoadingSpinnerProps = {
  className?: string
  size?: 'large' | 'medium' | 'small'
}

export function LoadingSpinner(props:LoadingSpinnerProps) {
  const { className, size } = props

  const loadingSpinnerClassName = cx('m--loading-spinner', className, {
    'm--loading-spinner--small': size == 'small',
    'm--loading-spinner--medium': size == 'medium',
    'm--loading-spinner--large': size == 'large',
  })

  return (
    <div className={loadingSpinnerClassName}>
      <Spinner className={moduleStyles.svg} />
    </div>
  )
}
