import { SVGProps } from 'react'
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSpinner = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="50px"
    height="50px"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle r={4} transform="matrix(-1 0 0 1 16 8)" fill="currentColor" />
    <circle r={4} transform="matrix(-1 0 0 1 16 24)" fill="currentColor" />
    <circle r={3} transform="matrix(-1 0 0 1 24 16)" stroke="currentColor" />
    <circle r={3} transform="matrix(-1 0 0 1 8 16)" stroke="currentColor" />
  </svg>
)
export default SvgSpinner
