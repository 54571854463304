import { PropsWithChildren } from 'react'
import moduleStyles from './Flag.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(moduleStyles)

type Type = 'high' | 'moderate' | 'low' | 'closed'
type Size = 'large' | 'default' | 'small'

interface Props extends PropsWithChildren {
  type?: Type
  size?: Size
  className?: string
}

export const Flag = ({ type, size, className, children }: Props) => {
  const typeClass = {
    [`m--type__high`]: type === `high`,
    [`m--type__moderate`]: type === `moderate`,
    [`m--type__low`]: type === `low`,
    [`m--type__closed`]: type === `closed`,
  }

  const sizeClass = {
    [`m--size__large`]: size === `large`,
    [`m--size__default`]: size === `default`,
    [`m--size__small`]: size === `small`,
  }

  return (
    <div className={cx(className)}>
      <span className={cx(typeClass, sizeClass)}>{children}</span>
    </div>
  )
}
