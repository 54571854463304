import { ReactNode, useRef, useState } from "react";
// import { Modal } from '../Modal';
import { Info } from '@fsg/icons'
import moduleStyles from "./HelpText.module.scss";

export type HelpTextProps = {
  children?: ReactNode;
  heading?: string;
}

export function HelpText(props: HelpTextProps) {
  const { children, heading } = props;
  const [showModal, setShowModal] = useState(false);
  const helpRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Info width="16" height="16" />
      <div
        className={moduleStyles[`m--help-text`]}
        ref={helpRef}
        onClick={() => setShowModal(true)}
      ></div>
      {/* <Modal anchor={helpRef} defaultShown={showModal} closeButton={true} headerText={heading || undefined}>{children}</Modal> */}
    </>
  )
};
