'use client'

import { useRequestCallback } from '@fsg/next-auth'

type Transformer = ((data: any) => any) | Partial<Record<'create' | 'update', (data: any) => any>>

export function useServiceFactory<APIRequestType, APIResponseType>(endpoint: string, transformers?: Transformer) {
  const { request } = useRequestCallback()

  const getTransformedData = (data: APIRequestType, operation: 'create' | 'update') => {
    if (typeof transformers === 'function') return transformers(data)
    if (transformers) return transformers[operation]?.(data) || data
    return data
  }

  return {
    read: async (id: string) => {
      return request<APIResponseType>({ path: `${endpoint}/${id}`, method: 'GET' })
    },
    create: async (data: APIRequestType) => {
      return request<APIResponseType>({ path: endpoint, method: 'POST', body: JSON.stringify(getTransformedData(data, 'create')) })
    },
    update: async (data: APIRequestType, id: string) => {
      return request<APIResponseType>({ path: `${endpoint}/${id}`, method: 'PUT', body: JSON.stringify(getTransformedData(data, 'update')) })
    },
    delete: async (id: string) => {
      return request<APIResponseType>({ path: `${endpoint}/${id}`, method: 'DELETE' })
    },
  }
}
