export const testData: any = {
  "contract": {
    "hrs": [
      {
        "date": 1648684800000,
        "value": 320
      },
      {
        "date": 1651276800000,
        "value": 320
      },
      {
        "date": 1653955200000,
        "value": 320
      },
      {
        "date": 1656547200000,
        "value": 320
      },
      {
        "date": 1659225600000,
        "value": 320
      },
      {
        "date": 1661904000000,
        "value": 320
      },
      {
        "date": 1664496000000,
        "value": 320
      },
      {
        "date": 1667174400000,
        "value": 320
      },
      {
        "date": 1669766400000,
        "value": 320
      }
    ],
    "amt": [
      {
        "date": 1648684800000,
        "value": 11520
      },
      {
        "date": 1651276800000,
        "value": 11520
      },
      {
        "date": 1653955200000,
        "value": 11520
      },
      {
        "date": 1656547200000,
        "value": 11520
      },
      {
        "date": 1659225600000,
        "value": 11520
      },
      {
        "date": 1661904000000,
        "value": 11520
      },
      {
        "date": 1664496000000,
        "value": 11520
      },
      {
        "date": 1667174400000,
        "value": 11520
      },
      {
        "date": 1669766400000,
        "value": 11520
      }
    ]
  },
  "total": {
    "hrs": [
      {
        "date": 1648684800000,
        "value": 320
      },
      {
        "date": 1651276800000,
        "value": 320
      },
      {
        "date": 1653955200000,
        "value": 320
      },
      {
        "date": 1656547200000,
        "value": 320
      },
      {
        "date": 1659225600000,
        "value": 320
      },
      {
        "date": 1661904000000,
        "value": 320
      },
      {
        "date": 1664496000000,
        "value": 320
      },
      {
        "date": 1667174400000,
        "value": 320
      },
      {
        "date": 1669766400000,
        "value": 320
      }
    ],
    "amt": [
      {
        "date": 1648684800000,
        "value": 11520
      },
      {
        "date": 1651276800000,
        "value": 11520
      },
      {
        "date": 1653955200000,
        "value": 11520
      },
      {
        "date": 1656547200000,
        "value": 11520
      },
      {
        "date": 1659225600000,
        "value": 11520
      },
      {
        "date": 1661904000000,
        "value": 11520
      },
      {
        "date": 1664496000000,
        "value": 11520
      },
      {
        "date": 1667174400000,
        "value": 11520
      },
      {
        "date": 1669766400000,
        "value": 11520
      }
    ]
  },
  "forecast": {
    "hrs": [
      {
        "date": 1648684800000,
        "value": 0
      },
      {
        "date": 1651276800000,
        "value": 0
      },
      {
        "date": 1653955200000,
        "value": 0
      },
      {
        "date": 1656547200000,
        "value": 0
      },
      {
        "date": 1659225600000,
        "value": 0
      },
      {
        "date": 1661904000000,
        "value": 0
      },
      {
        "date": 1664496000000,
        "value": 0
      },
      {
        "date": 1667174400000,
        "value": 0
      },
      {
        "date": 1669766400000,
        "value": 0
      }
    ],
    "amt": [
      {
        "date": 1648684800000,
        "value": 0
      },
      {
        "date": 1651276800000,
        "value": 11520
      },
      {
        "date": 1653955200000,
        "value": 11520
      },
      {
        "date": 1656547200000,
        "value": 11520
      },
      {
        "date": 1659225600000,
        "value": 11520
      },
      {
        "date": 1661904000000,
        "value": 11520
      },
      {
        "date": 1664496000000,
        "value": 11520
      },
      {
        "date": 1667174400000,
        "value": 11520
      },
      {
        "date": 1669766400000,
        "value": 11520
      }
    ]
  },
  "actual": {
    "hrs": [
      {
        "date": 1648684800000,
        "value": 0
      },
      {
        "date": 1651276800000,
        "value": 0
      },
      {
        "date": 1653955200000,
        "value": 0
      },
      {
        "date": 1656547200000,
        "value": 74
      },
      {
        "date": 1659225600000,
        "value": 74
      },
      {
        "date": 1661904000000,
        "value": 0
      },
      {
        "date": 1664496000000,
        "value": 0
      },
      {
        "date": 1667174400000,
        "value": 0
      },
      {
        "date": 1669766400000,
        "value": 0
      }
    ],
    "amt": [
      {
        "date": 1648684800000,
        "value": 0
      },
      {
        "date": 1651276800000,
        "value": 0
      },
      {
        "date": 1653955200000,
        "value": 0
      },
      {
        "date": 1656547200000,
        "value": 2410.64
      },
      {
        "date": 1659225600000,
        "value": 2410.64
      },
      {
        "date": 1661904000000,
        "value": 0
      },
      {
        "date": 1664496000000,
        "value": 0
      },
      {
        "date": 1667174400000,
        "value": 0
      },
      {
        "date": 1669766400000,
        "value": 0
      }
    ]
  }
};


export default testData;
