import { useContext, useEffect } from "react";
import { RadarChartContext } from '../RadarChart';
import { translateColorToSafeString } from '../../helpers';

type RadarAxisProps = {
  min: number;
  max: number;
  ticks: number;
  stroke?: string;
  textColor?: string;
}

export function RadarAxis(props :RadarAxisProps) {
  const {
    min,
    max,
    ticks,
    stroke = '#F1F1F5',
    textColor = '#6F7278'
  } = props;

  const { categories, getValuePointPosition, isCanvas, canvasRef, chartWidth, chartHeight } = useContext(RadarChartContext);

  const range = max - min;
  const interval = range / ticks;

  useEffect(() => {
    if (!isCanvas || !canvasRef?.current) return;

    const ctx = canvasRef.current.getContext('2d');
    const dpr = window.devicePixelRatio || 1;

    if (!ctx) return;

    for (const { id: category, label } of categories) {
      const points = [
        getValuePointPosition({ category, value: min }),
        getValuePointPosition({ category, value: max })
      ];

      ctx.beginPath();
      ctx.strokeStyle = translateColorToSafeString(stroke, canvasRef.current);
      ctx.lineWidth = 2 * dpr;
      ctx.moveTo(points[0].x, points[0].y);
      ctx.lineTo(points[1].x, points[1].y);
      ctx.stroke();

      const { x: labelX, y: labelY } = getValuePointPosition({ category, value: max + (0.08 * (max - min)) });

      ctx.beginPath();
      ctx.strokeStyle = translateColorToSafeString(textColor, canvasRef.current);
      ctx.fillStyle = translateColorToSafeString(textColor, canvasRef.current);
      ctx.lineWidth = 1;
      ctx.textAlign = 'center';
      ctx.font = `${dpr * 12}px Barlow`;
      ctx.fillText(
        label,
        labelX,
        labelY
      );
      ctx.stroke();
    }

    for (let value = interval; value <= max; value += interval) {
      const points = categories.map(({ id: category }) => getValuePointPosition({ category, value }));
      if (points.length == 0) continue;

      ctx.beginPath();
      ctx.strokeStyle = translateColorToSafeString(stroke, canvasRef.current);
      ctx.lineWidth = 2 * dpr;
      ctx.moveTo(points[0].x, points[0].y);

      for (const { x, y } of points) {
        ctx.lineTo(x, y);
      }

      ctx.closePath();
      ctx.stroke();

      const { x: labelX, y: labelY } = getValuePointPosition({
        category: categories[0].id,
        value
      });

      ctx.beginPath();
      ctx.strokeStyle = translateColorToSafeString(textColor, canvasRef.current);
      ctx.fillStyle = translateColorToSafeString(textColor, canvasRef.current);
      ctx.lineWidth = 1;
      ctx.textAlign = 'center';
      ctx.font = `${dpr * 12}px Barlow`;
      ctx.fillText(
        `${value}`,
        labelX,
        labelY
      );
      ctx.stroke();
    }
  }, [isCanvas, canvasRef, chartWidth, chartHeight, min, max, ticks]);

  if (isCanvas) return null;

  const lineNodes = [];
  const textNodes = [];

  for (const { id: category, label } of categories) {
    const points = [
      getValuePointPosition({ category, value: min }),
      getValuePointPosition({ category, value: max })
    ].map(({ x, y }) => `${x},${y}`).join(' ');

    lineNodes.push(
      <polyline
        key={`${category}-tick`}
        points={points}
        strokeWidth={2}
        stroke={stroke} />
    );

    const { x: labelX, y: labelY } = getValuePointPosition({ category, value: max + (0.08 * (max - min)) });

    textNodes.push(
      <text
        key={`${category}-label`}
        fill={textColor}
        style={{ fontSize: 12 }}
        textAnchor="middle"
        alignmentBaseline="middle"
        x={labelX}
        y={labelY}>
        {label}
      </text>
    );
  }

  for (let value = interval; value <= max; value += interval) {
    const points = categories.map(({ id: category }) => {
      const { x, y } = getValuePointPosition({ category, value });
      return `${x},${y}`;
    }).join(' ');

    lineNodes.push(
      <polygon
        key={`${value}-tick`}
        points={points}
        fill="none"
        strokeWidth={2}
        stroke={stroke} />
    );

    const { x: labelX, y: labelY } = getValuePointPosition({
      category: categories[0].id,
      value
    });

    textNodes.push(
      <text
        key={`${value}-label`}
        fill={textColor}
        style={{ fontSize: 12 }}
        textAnchor="middle"
        alignmentBaseline="middle"
        x={labelX}
        y={labelY}>
        {value}
      </text>
    );
  }

  return (
    <g>
      {lineNodes}
      {textNodes}
    </g>
  );

};