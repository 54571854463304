import { forwardRef, MouseEvent, MouseEventHandler, Ref } from 'react';
import cn from 'classnames';
import { Card, CardProps } from '../Card';
import moduleStyles from './CardButton.module.scss';

export type CardButtonProps = CardProps & {
  ref?: Ref<HTMLDivElement>;
  onClick?: MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
}

export const CardButton = forwardRef<HTMLDivElement, CardButtonProps>(function CardButton(props: CardButtonProps, _ref) {
  const {
    className,
    loading,
    children,
    disabled,
    onKeyDown,
    ...rest
  } = props;

  const cardClassName = cn(moduleStyles['card-button'], className, {
    [moduleStyles['card-button--disabled']]: disabled
  });

  return (
    <Card
      ref={_ref}
      className={cardClassName}
      {...rest}
      onKeyDown={(event) => {
        const { onClick } = rest;

        if (event.key == 'Enter' && onClick) {
          event.preventDefault();
          onClick(event as unknown as MouseEvent<HTMLDivElement>);
          return false;
        }
        onKeyDown && onKeyDown(event);
      }}
      loading={loading}
      role="button"
      aria-disabled={disabled || loading}
      tabIndex={0}>
      {children}
    </Card>
  );
});

