import {
  FC,
  useState,
  forwardRef,
  useId,
  SelectHTMLAttributes,
  useRef,
  useEffect,
  KeyboardEvent,
} from 'react'
import cn from 'classnames'
import { LoadingSpinner } from '../LoadingSpinner'
// import { ToolTip } from '../ToolTip';
import { ChevronDown } from '@fsg/icons'
import moduleStyles from './SelectInput.module.scss'

export type SelectInputProps = SelectHTMLAttributes<HTMLSelectElement> & {
  loading?: boolean
  icon?: FC
  placeholder?: string
  label?: string
  onEnter?: (e: KeyboardEvent<HTMLSelectElement>) => void
  options?: {
    label: string
    value: string | number
  }[]
  disabledReason?: string
}

export const SelectInput = forwardRef<HTMLSelectElement, SelectInputProps>(function SelectInput(
  props: SelectInputProps,
  _ref,
) {
  const {
    icon: Icon,
    className,
    loading,
    placeholder = 'Select',
    label,
    onEnter,
    value,
    children,
    options,
    disabledReason,
    ...rest
  } = props

  const localRef = useRef<HTMLSelectElement>(null)

  useEffect(() => {
    if (!_ref) return

    if (typeof _ref === 'function') {
      _ref(localRef.current)
    } else {
      _ref.current = localRef.current
    }
  }, [localRef.current])

  const selectProps: SelectHTMLAttributes<HTMLSelectElement> = {
    ...rest,
  }

  const labelRef = useRef<HTMLLabelElement>(null)
  const id = useId()
  const [focused, setFocused] = useState(false)

  const dirty = value !== undefined && value !== null

  const labelClassName = cn(moduleStyles['m--select-input'], className, {
    [moduleStyles['m--select-input--focus']]: focused,
    [moduleStyles['m--select-input--disabled']]: selectProps.disabled,
    [moduleStyles['m--select-input--multiple']]: selectProps.multiple,
    [moduleStyles['m--select-input--loading']]: loading,
    [moduleStyles['m--select-input--dirty']]: dirty,
    [moduleStyles['m--select-input--with-label']]: !!label,
  })

  if (onEnter) {
    selectProps.onKeyDown = e => {
      return e.key == 'Enter' ? onEnter(e) : true
    }
  }

  return (
    <>
      <label htmlFor={id} ref={labelRef} className={labelClassName}>
        {loading ? (
          <div className={moduleStyles['m--loading-overlay']}>
            <LoadingSpinner size="small" />
          </div>
        ) : null}
        {Icon ? <Icon /> : null}
        {label ? (
          <span onClick={() => localRef.current?.click()} className={moduleStyles['m--label']}>
            {label}
          </span>
        ) : null}
        <select
          {...selectProps}
          ref={localRef}
          id={id}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={value ?? (selectProps.multiple ? [] : '')}
        >
          {!selectProps.multiple && !label ? (
            <option value={''} disabled>
              {placeholder}
            </option>
          ) : null}
          {options
            ? options.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))
            : null}
          {children}
        </select>
        {!selectProps.multiple ? <ChevronDown width="16px" height="16px" /> : null}
      </label>
      {/* {selectProps.disabled && disabledReason ? (
        <ToolTip delay={100} anchor={labelRef}>
          {disabledReason}
        </ToolTip>
      ) : null} */}
    </>
  )
})
