import cn from 'classnames';
import moduleStyles from './HeaderText.module.scss';

export type HeaderTextProps = {
  className?: string;
  children: string | JSX.Element;
}

export function HeaderText(props: HeaderTextProps) {
  const { className, children } = props;

  //if (!(typeof children == `string`)) throw new Error(`HeaderText requires a single string child`);

  return (
    <div className={cn(moduleStyles['m--header'], 'text-4xl', className)}>
      {typeof children == 'string' ? <span>{children}</span> : <>{children}</>}
    </div>
  )
};
