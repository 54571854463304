import React, { HtmlHTMLAttributes, PropsWithChildren } from 'react'

import { cn } from '@fsg/utils'

interface Props extends PropsWithChildren<HtmlHTMLAttributes<HTMLDivElement>> {
  color?: string
}

export function Label(props: Props) {
  const { color = 'bg-color-primary-dark', className, ...divProps } = props

  const labelClass = cn('text-white text-sm font-bold px-md rounded-lg', color, className)

  return <div className={labelClass} {...divProps} />
}
