import { ReactElement, useContext } from "react";
import {DonutChartContext, DonutChartValueType} from '../DonutChart';
import { translateColorToSafeString } from '../../helpers';

type DonutProps = {
  textColor?: string;
  value: DonutChartValueType[]
}

const colors = [
  '#ff974b',
  '#0061ff',
  '#ffc543',
  '#81c43b',
  '#912018',
  '#01c0f6',
  '#553aff',
  '#a461d8'
];

export function Donut(props:DonutProps) {
  const { textColor = '#6F7278', value } = props;

  const { categories, chartWidth } = useContext(DonutChartContext);

  const donutChunks: ReactElement[] = [];
  const keyLabels = [];

  const total = value.reduce((acc, cur) => acc + cur.value, 0);

  const donutWidth = chartWidth - 20;
  const strokeWidth = donutWidth / 36;
  const pos = donutWidth / 6;
  const radius = pos - strokeWidth / 2;
  const circumference = Math.PI * 2 * radius;
  let strokeOffset = - circumference / 4;

  let top = 20;
  let i = 0;

  const tallerThanChart = value.length * 32 > (donutWidth / 3);

  value.sort((a, b) => b.value - a.value);

  for (const chunk of value) {

    const category = categories.find(({ id }) => id === chunk.category)!;

    const { id, label } = category;
    let { color } = category;
    if (!color) color = colors[i % colors.length];

    i++;

    // const chunk = value.find(({ category }) => category === id);
    //if (!chunk) continue;

    const percent = chunk.value / total;
    const strokeStart = percent * circumference;

    donutChunks.push(
      <circle
        key={id}
        cx={pos + 5}
        cy={pos + 5}
        r={radius}
        fill={'transparent'}
        stroke={translateColorToSafeString(color)}
        strokeWidth={strokeWidth}
        data-hover-stroke-width={strokeWidth + 2}
        strokeDasharray={`${ strokeStart } ${ circumference - strokeStart }`}
        strokeDashoffset={-strokeOffset}
      />
    );
    strokeOffset += strokeStart;

    let left = i % 2 === 1 ? donutWidth / 3 : donutWidth / 3 * 2;
    if (!tallerThanChart) left = donutWidth / 2;

    keyLabels.push(
      <g
        key={id}
        transform={`translate(${ left + 24 }, ${ top })`}
      >
        <circle
          cx={0}
          cy={-5}
          r={10}
          fill={translateColorToSafeString(color)}
        />
        <text
          fill={translateColorToSafeString(textColor)}
          fontSize={12}
          fontFamily="Barlow"
          textAnchor="left"
          alignmentBaseline="baseline"
          x={20}
          y={0}
        >
          {label} &nbsp; <tspan>{chunk.value}</tspan>
        </text>
      </g>
    );

    if (i % 2 === 0 || !tallerThanChart) top += 30;

  }

  return (
    <>

      {/* <circle className="donut-hole" cx={pos} cy={pos} r={pos - strokeWidth / 2} fill="#fff" />
      <circle className="donut-ring" cx={pos} cy={pos} r={pos - strokeWidth / 2} fill="transparent" stroke="#ccc" strokeWidth={strokeWidth} /> */}
      {donutChunks}
      <circle className="donut-hole" cx={pos + 5} cy={pos + 5} r={pos - strokeWidth / 2} fill="#fff" />
      {keyLabels}
      <text
        x={pos + 5}
        y={pos - strokeWidth + 5}
        fill={translateColorToSafeString(`#000`)}
        fontSize={strokeWidth * 2}
        fontFamily="Barlow"
        fontWeight="bold"
        textAnchor="middle"
        alignmentBaseline="central"
      >
        {total}
      </text>
      <text
        x={pos + 5}
        y={pos + strokeWidth + 5}
        fill={translateColorToSafeString(textColor)}
        fontSize={strokeWidth}
        fontFamily="Barlow"
        fontWeight="normal"
        textAnchor="middle"
        alignmentBaseline="central"
      >
        Total</text>
    </>
  );
};
