'use client'
import { ButtonHTMLAttributes, Ref } from 'react'
// import { useRouter } from 'next/navigation'
import Link from 'next/link'
import cn from 'classnames/bind'
import moduleStyles from './LinkButton.module.scss'

let cx = cn.bind(moduleStyles)

export type LinkButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & {
  ref?: Ref<HTMLButtonElement>
  className?: string | null
  buttonType?: 'primary' | 'secondary' | 'ghost' | null
  size?: 'large' | 'medium' | 'small' | null
  active?: boolean | null
  loading?: boolean | null
  withIcon?: boolean | null
  href: string
}

export function LinkButton(props: LinkButtonProps) {
  const { className, buttonType, active, withIcon, size, children, loading, href, ...rest } = props
  // const router = useRouter()

  const buttonClassName = cx('button', className, {
    'button--primary': buttonType == 'primary',
    'button--secondary': buttonType == 'secondary',
    'button--ghost': buttonType == 'ghost',
    'button--small': size == 'small',
    'button--medium': size == 'medium',
    'button--large': size == 'large',
    'button--active': !!active,
    'button--has-icon': !!withIcon,
    'button--loading': !!loading,
  })

  if (loading) rest.disabled = true

  return (
    <Link href={href || '#'}>
      <button
        {...rest}
        role="button"
        aria-disabled={rest.disabled}
        //   ref={ref}
        className={buttonClassName}
      >
        {/* {loading && (
        <div className={moduleStyles[`loading-overlay`]}>
          <LoadingSpinner size="small" />
        </div>
      )} */}
        {children}
      </button>
    </Link>
  )
}
