import cn from 'classnames'

import { Calendar } from '@fsg/icons'

import moduleStyles from './DateText.module.scss'

export type DateTextProps = {
  className?: string
  symbolClassName?: string
  children: string
  weekday?: 'long' | 'short' | 'narrow'
  year?: 'numeric' | '2-digit'
  month?: 'long' | 'short' | 'narrow' | 'numeric' | '2-digit'
  day?: 'numeric' | '2-digit'
  hour?: '2-digit' | 'numeric'
  minute?: '2-digit' | 'numeric'
  hideTime?: boolean
  timeZone?: string
  showIcon?: boolean
}

export function DateText(props: DateTextProps) {
  const {
    weekday,
    year = 'numeric',
    month = '2-digit',
    day = 'numeric',
    hour = 'numeric',
    minute = '2-digit',
    className,
    symbolClassName,
    hideTime = false,
    children,
    timeZone = 'UTC',
    showIcon = true
  } = props

  if (!(typeof children == 'string')) {
    throw new Error('DateText requires a single string child')
  }

  let parsed_child: string | number
  if (!isNaN(children as unknown as number)) {
    parsed_child = parseInt(children)
  } else {
    parsed_child = children
  }

  const date = new Date(parsed_child)
  const not_a_date = date.toString() == 'Invalid Date'

  let display_date: string

  if (!not_a_date) {
    display_date = date.toLocaleDateString(undefined, {
      weekday,
      year,
      month,
      day,
      timeZone,
    })
    if (!hideTime) {
      display_date +=
        // eslint-disable-next-line prefer-template
        ' ' +
        date.toLocaleTimeString(undefined, {
          hour,
          minute,
          timeZone,
        })
    }
  } else {
    display_date = children
  }

  return (
    <div className={cn(moduleStyles['m--date-text'], className)}>
      {showIcon ? <Calendar className={cn(moduleStyles['m--symbol'], symbolClassName)} /> : null}
      <span className={moduleStyles['m--value']}>{display_date}</span>
    </div>
  )
}
