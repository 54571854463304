import { createContext, RefObject } from 'react';

export type RadarChartCategory = {
  label: string;
  id: string;
}

export type RadarChartValue = {
  category: string;
  value: number;
}

type SVGCoords = {
  x: number;
  y: number;
}

export type RadarChartContextType = {
  chartWidth: number;
  chartHeight: number;

  categories: RadarChartCategory[];
  getValuePointPosition: (value: RadarChartValue) => SVGCoords;

  canvasRef?: RefObject<HTMLCanvasElement>;
  isCanvas: boolean;
  canvasRefreshCounter: number;
}

export const RadarChartContext = createContext<RadarChartContextType>({ 
  chartWidth: 0,
  chartHeight: 0,
  
  categories: [],
  getValuePointPosition: () => ({ x: 0, y: 0 }),

  isCanvas: false,
  canvasRefreshCounter: 0
});
