import React, { createContext, MutableRefObject, useContext, useEffect, useRef } from 'react'
import classNames from 'classnames/bind'

import { X } from '@fsg/icons'

import moduleStyles from './Modal.module.scss'

const cx = classNames.bind(moduleStyles)

type ModalContextType = {
  handleCloseModal: () => void
  modalRef: MutableRefObject<HTMLDialogElement | null>
  size: 'base' | 'large' | 'full'
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)
const { Provider } = ModalContext

const styles = {
  modal: {
    dialog: 'w-[400px] rounded-[16px]',
    header: 'mb-[6px] text-center text-[20px] font-semibold',
    content: 'flex flex-col px-[24px] pb-[24px]',
    description: 'text-center text-[16px]',
    actions: 'mt-[24px] flex justify-center gap-lg',
  },
  largeModal: {
    dialog: 'rounded-[16px]',
    header: 'text-[16px] font-semibold',
    content: 'h-full',
    description: '',
    actions: 'flex justify-end p-xl gap-lg',
  },

  fullScreen: {
    header: 'text-[16px] font-semibold',
    content: 'flex flex-col h-full',
    description: 'flex-1',
    actions: 'mt-[24px] flex justify-end p-xl gap-lg',
  },
}

export function useModalContext() {
  const context = useContext(ModalContext)

  if (!context) throw new Error('useModalContext must be called within a Modal context provider')
  return context
}

export type ModalProps = React.PropsWithChildren<{
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  size?: 'base' | 'large' | 'full'
  hideCloseButton?: boolean
  onClose?: () => void
  className?: string
}>

export function useModal(defaultOpened = false, { onClose }: Partial<ModalProps> = {}) {
  const [open, setOpen] = React.useState(defaultOpened)
  const showModal = React.useCallback(() => setOpen(true), [])
  const close = React.useCallback(() => setOpen(false), [])

  return React.useMemo(
    () => ({
      api: {
        showModal,
        close,
      },
      // returns the required props for a Modal component
      register: () => ({
        open,
        setOpen,
        onClose,
      }),
    }),
    [open, close, showModal, onClose],
  )
}

export function Modal({ open, setOpen, children, size = 'base', onClose, hideCloseButton = false, className }: ModalProps) {
  const modalRef = useRef<HTMLDialogElement | null>(null)

  useEffect(() => {
    const modalElement = modalRef.current

    if (!modalElement) return

    if (open) {
      modalElement.showModal()
      modalElement.focus()
    } else {
      modalElement.close()
    }
  }, [open])

  function handleCloseModal() {
    if (onClose !== undefined) onClose()
    setOpen(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === 'Escape') {
      handleCloseModal()
    }
  }

  const contextValue: ModalContextType = {
    handleCloseModal,
    modalRef,
    size: size,
  }

  return (
    <Provider value={contextValue}>
      <dialog
        ref={modalRef}
        onKeyDown={handleKeyDown}
        className={className ? className : size === 'base' ? styles.modal.dialog : size === 'large' ? styles.largeModal.dialog : cx('modal-dialog')}
      >
        <div className="fixed right-0 top-0 z-[99999]" id="modal-notification-portal"></div>
        <div id="modal-sheet-portal"></div>
        {size === 'base' && !hideCloseButton && (
          <div className="p-[8px]">
            <X className="m-[6px] ml-auto" role="button" onClick={handleCloseModal} tabIndex={0} />
          </div>
        )}
        <div className={size === 'base' ? styles.modal.content : size === 'full' ? styles.fullScreen.content : styles.largeModal.content}>
          {children}
        </div>
      </dialog>
    </Provider>
  )
}

interface ModalComponents extends React.PropsWithChildren {
  className?: string
  hideCloseButton?: boolean
}

function Heading({ children, className, hideCloseButton = false }: ModalComponents) {
  const { size, handleCloseModal } = useModalContext()
  return size === 'base' ? (
    <h2 className={styles.modal.header}>{children}</h2>
  ) : (
    <div className={`flex items-center border-b border-gray-300 p-sm ${styles.largeModal.header} ${className}`}>
      <h2> {children}</h2>
      {!hideCloseButton && <X className="m-[6px] ml-auto" role="button" onClick={handleCloseModal} tabIndex={0} />}
    </div>
  )
}

function Description({ children, className }: ModalComponents) {
  const { size } = useModalContext()
  return <p className={size === 'base' ? styles.modal.description : styles.largeModal.description}>{children}</p>
}

function Content({ children, className }: ModalComponents) {
  const { size } = useModalContext()
  return (
    <div className={size === 'base' ? styles.modal.description : size === 'full' ? styles.fullScreen.description : styles.largeModal.description}>
      {children}
    </div>
  )
}

type ActionsProps = {
  children: React.ReactNode | ((props: { handleCloseModal: () => void }) => React.ReactNode)
  className?: string
}

function Actions({ children, className }: ActionsProps) {
  const { handleCloseModal, size } = useModalContext()
  let content

  if (typeof children === 'object') {
    content = children
  } else if (typeof children === 'function') {
    content = children({ handleCloseModal })
  }
  return <div className={className ? className : size === 'base' ? styles.modal.actions : styles.largeModal.actions}>{content}</div>
}

Modal.Heading = Heading
Modal.Description = Description
Modal.Actions = Actions
Modal.Content = Content
