import { FC, forwardRef, ReactElement, ReactNode, SVGAttributes, useRef } from 'react'
import cn from 'classnames'
import { Card, CardProps } from '../Card'
// import { Modal } from '../Modal';
import { ArrowDown, ArrowUp, Info as InfoIcon } from '@fsg/icons'
import moduleStyles from './HeroKPICard.module.scss'

export type HeroKPICardProps = Omit<CardProps, 'children'> & {
  icon?: FC<SVGAttributes<SVGElement>>
  other?: ReactElement
  label: string | ReactElement
  labelClassName?: string
  primary: ReactNode
  secondary?: string
  direction?: 'up' | 'down'
  secondaryColor?: 'red' | 'green'
  helpText?: ReactNode
}

export const HeroKPICard = forwardRef<HTMLDivElement, HeroKPICardProps>(function HeroKPICard(props: HeroKPICardProps, _ref) {
  const {
    icon: Icon,
    other,
    className,
    label,
    labelClassName,
    primary,
    secondary,
    secondaryColor = 'green',
    direction,
    helpText,
    ...cardButtonProps
  } = props

  const helpRef = useRef<SVGSVGElement>(null)

  const cardClassName = cn(moduleStyles['m--hero-kpi-card'], className)

  return (
    <Card ref={_ref} className={cardClassName} {...cardButtonProps}>
      {Icon ? (
        <div className={moduleStyles['m--icon-wrapper']}>
          <Icon width={26} height={26} />
        </div>
      ) : other ? (
        <div className={moduleStyles['m--other-wrapper']}>{other}</div>
      ) : null}
      <div className={moduleStyles['m--value-wrapper']}>
        <div className={cn('text-2xl font-bold', labelClassName)}>
          {label}
          {helpText ? (
            <>
              <InfoIcon className={moduleStyles['m--help-text']} width="16" stroke="var(--gray-dark)" ref={helpRef} />
              {/* <Modal closeButton={true} headerText={label} anchor={helpRef}>{helpText}</Modal> */}
            </>
          ) : null}
        </div>
        <div className={cn(moduleStyles['m--primary'], 'text-5xl font-bold')}>
          <span>{primary}</span>
        </div>
        {secondary !== undefined || direction ? (
          <div
            className={cn(moduleStyles['m--secondary'], moduleStyles[`m--secondary--${secondaryColor}`], 'text-xl font-bold', {
              [moduleStyles['m--secondary--long']]: secondary && secondary.length > 10,
            })}
          >
            {secondary !== undefined ? <span>{secondary}</span> : null}
            {direction == 'down' ? <ArrowDown /> : null}
            {direction == 'up' ? <ArrowUp /> : null}
          </div>
        ) : null}
      </div>
    </Card>
  )
})
