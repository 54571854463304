import { PropsWithChildren } from "react"
import Image from "next/image" 
import cn from "classnames/bind"
import DrilldownIcon from "./drilldownIcon.svg"
import moduleStyles from './DrilldownCell.module.scss'

const cx = cn.bind(moduleStyles)

type DrilldownProps = PropsWithChildren & {
  href: string
}
export function DrilldownCell(props: DrilldownProps) {
  const { href, children } = props

  return (
    <span className={cx('drilldown-cell')}>
      <span>{children}</span>
      <span>
        <a className={cx('drilldown-link')} href={href} style={{ textDecoration: 'underline' }}>
          <Image src={DrilldownIcon} className={cx('drilldown-icon')} alt="drilldown icon" />
        </a>
      </span>
    </span>
  )
}
