'use client'
import { useRef, useCallback, useState } from 'react';
import { DonutChartContext, DonutChartCategoryType, DonutChartContextType } from './';
import cn from 'classnames';
import moduleStyles from './DonutChart.module.scss';

export type DonutChartProps = {
  className?: string;
  chartClassName?: string;
  categories: DonutChartCategoryType[];
  strokeWidth?: number;
  children: JSX.Element | JSX.Element[];
}

const degreeToRadians = (degree: number) => (Math.PI / 180) * degree;

export function DonutChart (props: DonutChartProps) {
  const {
    className,
    children,
    chartClassName,
    categories,
    ...rest
  } = props;

  const [wrapperBounds, setWrapperBounds] = useState<DOMRect>();
  const handleWrapper = useCallback((node: HTMLDivElement) => {
    if (node) setWrapperBounds(node.getBoundingClientRect());
  }, [setWrapperBounds]);

  const svgRef = useRef<SVGSVGElement>(null);

  const width = wrapperBounds?.width || 0;
  const paddingForLabels = 150;

  const contextValue: DonutChartContextType = {
    chartWidth: width,
    chartHeight: width / 2,
    categories
  };

  const chartChildren = (
    <DonutChartContext.Provider value={contextValue}>
      {children}
    </DonutChartContext.Provider>
  );

  return (
    <div
      ref={handleWrapper}
      className={cn(moduleStyles['donut'], className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${ width } ${ width / 3 }`}
        className={chartClassName}
        ref={svgRef}
        {...rest}>
        {chartChildren}
      </svg>
    </div>
  );
};
