import cn from 'classnames/bind'
import moduleStyles from './SubheaderText.module.scss'

const cx = cn.bind(moduleStyles)

export type SubheaderTextProps = {
  className?: string
  children: string | JSX.Element
}

export function SubheaderText(props: SubheaderTextProps) {
  const { className, children } = props

  return (
    <div className={cx('m--subheader', 'font-semibold text-4xl', className)}>
      {typeof children == 'string' ? <span>{children}</span> : <>{children}</>}
    </div>
  )
}
