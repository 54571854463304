import { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

type PortalProps = {
  selectors: string
}
export function Portal(props: PropsWithChildren<PortalProps>) {
  const { children, selectors } = props

  const portalRoot = document.querySelector(selectors)

  // If portal root is not found,
  // React wouldn't know where to render the children
  // This covers cases where portal root is either not found or not mounted
  if (!portalRoot) return null

  return createPortal(children, portalRoot)
}
