'use client'

import { createContext, useEffect, useState } from 'react'

type OverlayContentContext = {
  root: HTMLDivElement
}

export const useOverlayContentContext = () => {
  const [overlayContentRoot, setOverlayContentRoot] = useState<HTMLElement | null>(null)
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setOverlayContentRoot(document.querySelector<HTMLElement>('#overlay-content-root'))
    setMounted(true)
    const context = createContext<OverlayContentContext>({
      root: document.getElementById('overlay-content-root') as HTMLDivElement,
    })
    return () => setMounted(false)
  }, [])
  return {
    overlayContentRoot,
    mounted,
  }
}
