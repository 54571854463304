import * as Popover from '@radix-ui/react-popover'
import { ButtonV2 } from '../ButtonV2'

import { MoreHorizontal } from '@fsg/icons'

export function MoreActions(props: React.PropsWithChildren) {
  return props.children ? (
    <Popover.Root>
      <Popover.PopoverTrigger>
        <ButtonV2 variant="ghost" className="px-xs pb-xs pt-xs" size="lg">
          <MoreHorizontal />
        </ButtonV2>
      </Popover.PopoverTrigger>
      <Popover.PopoverContent align="end">
        <div className="flex flex-col gap-sm">{props.children}</div>
      </Popover.PopoverContent>
    </Popover.Root>
  ) : null
}
