import { FocusEvent, useCallback, useState, MouseEvent, useId, useRef, SVGAttributes, MouseEventHandler, FC } from "react";
import cn from "classnames";
import { TextInput, TextInputProps } from '../TextInput';
import { Button } from '../Button';
import { X as XIcon } from '@fsg/icons'
import moduleStyles from "./SpookyTextInput.module.scss";

export type SpookyTextInputProps = Omit<TextInputProps, 'icon' | 'value'> & {
  icon: FC<SVGAttributes<SVGElement>>;
  value: string | null | undefined;
  onClose?: MouseEventHandler<HTMLButtonElement>
  width?: number | string;
}

export function SpookyTextInput(props: SpookyTextInputProps) {
  const {
    icon: Icon,
    onFocus,
    onClose,
    value,
    className,
    width,
    ...rest
  } = props;

  const label = useRef<HTMLLabelElement>(null);
  const id = useId();

  const [expanded, setExpanded] = useState<boolean>(
    value !== undefined && value !== null
  );

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setExpanded(true);
      onFocus && onFocus(event);
    },
    [onFocus, setExpanded]
  );

  const handleClear = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setExpanded(false);
      onClose && onClose(event);
    },
    [setExpanded, onClose]
  );

  return (
    <div
      onClick={() => label.current?.click()}
      className={cn(moduleStyles['spooky-wrapper'], {
        [moduleStyles['spooky-wrapper--expanded']]: expanded,
        [moduleStyles['spooky-wrapper--disabled']]: rest.disabled,
      },
        className
      )}
      style={{
        width: width ? width : '',
      }}
    >
      <label ref={label} className={moduleStyles['spooky-icon-wrapper']} htmlFor={id}>
        <Icon className={moduleStyles['spooky-icon']} />
      </label>
      <TextInput
        id={id}
        value={value || ''}
        className={cn(moduleStyles['spooky-input'], {
          [moduleStyles['spooky-input--expanded']]: expanded,
          [moduleStyles['spooky-input--fullWidth']]: width,
        })}
        onFocus={handleFocus}
        {...rest}
      />
      {expanded ? (
        <Button
          disabled={rest.disabled}
          withIcon
          buttonType="ghost"
          size="small"
          className={moduleStyles['spooky-clear-button']}
          onClick={handleClear}
        >
          <XIcon />
        </Button>
      ) : null}
    </div>
  );
};
