import { useContext, useCallback, forwardRef, useState, useEffect } from 'react';
import { ChartContext, ChartDataSourceContext } from '../Chart';
import { ChartPoint } from '../ChartPoint';
import { formatFriendlyDate, translateColorToSafeString } from '../../helpers';
import moduleStyles from './Line.module.scss';

export type LineProps = {
  stroke?: string;
  tooltips?: boolean;
  tooltipValueFormat?: (value: number | string) => string;
  draw?: boolean;
  baseline?: number;
  strokeDasharray?: string;
}

export const Line = forwardRef<SVGPolylineElement, LineProps>(function Line(props, _ref) {
  const {
    tooltips = false,
    tooltipValueFormat = (n) => n,
    draw = false,
    stroke = 'black',
    baseline = 0,
    ...rest
  } = props;

  const { chartWidth, chartHeight, chartPadTop, chartPadLeft, isCanvas, canvasRef, canvasRefreshCounter } = useContext(ChartContext);
  const { data, tooltipLabel, tooltipValueLabel, valueScale, timeScale, mapValue } = useContext(ChartDataSourceContext);
  const [polylineLength, setPolylineLength] = useState(0);

  const handlePolyline = useCallback((node: SVGPolylineElement) => {
    if (typeof _ref === 'function') {
      _ref(node);
    } else if (_ref) {
      _ref.current = node;
    }

    if (draw && node) {
      setPolylineLength(node.getTotalLength());
    }
  }, [setPolylineLength, _ref]);

  useEffect(() => {
    if (!isCanvas || !canvasRef?.current) return;
    if (data.length == 0 || !valueScale || !timeScale) return;

    const { strokeDasharray } = rest;

    const { min: minDate, max: maxDate } = timeScale;
    const { min: minValue, max: maxValue } = valueScale;

    if (minValue == Infinity || maxValue == -Infinity) return;

    let positionedData = data.map(({ value, date }) => ({
      top: minValue == maxValue ? 1 : 1 - ((value - minValue) / (maxValue - minValue)),
      left: (date - minDate) / (maxDate - minDate),
      friendlyDate: formatFriendlyDate(date),
      friendlyValue: tooltipValueFormat(mapValue ? mapValue(value) : value)
    }));

    if (baseline > 0) {
      positionedData = positionedData.map((datum) => {
        const totalHeight = 1 - baseline;

        return {
          ...datum,
          top: datum.top * totalHeight
        };
      });
    }

    const ctx = canvasRef.current.getContext('2d');
    const dpr = window.devicePixelRatio || 1;

    if (!ctx) return;

    ctx.strokeStyle = translateColorToSafeString(stroke, canvasRef.current);
    ctx.lineWidth = 2 * dpr;

    ctx.beginPath();

    if (strokeDasharray) {
      ctx.setLineDash(strokeDasharray.split(' ').map((n) => parseInt(n) * dpr));
    } else {
      ctx.setLineDash([]);
    }

    let moved = false;

    for (const point of positionedData) {
      const left = (point.left * chartWidth) + chartPadLeft;
      const top = (point.top * chartHeight) + chartPadTop;

      if (!moved) {
        ctx.moveTo(left * dpr, top * dpr);
        moved = true;
      } else {
        ctx.lineTo(left * dpr, top * dpr);
      }
    }

    ctx.stroke();
  }, [isCanvas, canvasRef, canvasRefreshCounter, chartWidth, chartHeight, chartPadTop, chartPadLeft, data, valueScale, timeScale]);

  if (isCanvas) return null;

  if (data.length == 0 || !valueScale || !timeScale) return null;

  const { min: minDate, max: maxDate } = timeScale;
  const { min: minValue, max: maxValue } = valueScale;

  if (minValue == Infinity || maxValue == -Infinity) return null;

  let positionedData = data.map(({ value, date, label }) => ({
    top: minValue == maxValue ? 1 : 1 - ((value - minValue) / (maxValue - minValue)),
    left: (date - minDate) / (maxDate - minDate),
    friendlyDate: formatFriendlyDate(date),
    friendlyValue: tooltipValueFormat(mapValue ? mapValue(value) : value),
    label
  }));

  if (baseline > 0) {
    positionedData = positionedData.map((datum) => {
      const totalHeight = 1 - baseline;

      return {
        ...datum,
        top: datum.top * totalHeight
      };
    });
  }

  const polylinePoints = positionedData.map((point) => {
    return `${(point.left * chartWidth) + chartPadLeft},${(point.top * chartHeight) + chartPadTop}`;
  }).join(' ');

  return (
    <>
      <polyline
        ref={handlePolyline}
        fill="none"
        stroke={stroke}
        strokeWidth="2"
        strokeDasharray={draw ? polylineLength : undefined}
        strokeDashoffset={draw ? polylineLength : undefined}
        className={draw ? moduleStyles['draw'] : undefined}
        {...rest}
        points={polylinePoints} />
      {tooltips ? (
        positionedData.map(({ top, left, friendlyDate, friendlyValue, label }, index) => (
          <ChartPoint
            key={index}
            top={top}
            left={left}
            color={stroke}
            label={(
              <>
                <div><strong>{label}</strong></div>
                <div><strong>{tooltipLabel}</strong></div>
                <div><span>{tooltipValueLabel}{friendlyValue}</span></div>
                <div><span>{friendlyDate}</span></div>
              </>
            )} />
        ))) : null}
    </>
  );
});
