'use client'
import React from 'react'

const KONAMI_CODE = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a']

interface KonamiOptions {
  code?: string[]
}

const defaultAction = () => {
  window.alert('Konami Code entered!')
}

export function useKonami(action: () => void = defaultAction, { code = KONAMI_CODE }: KonamiOptions = {}) {
  const [input, setInput] = React.useState<string[]>([])

  const onKeyUp = React.useCallback(
    (e: KeyboardEvent) => {
      const newInput = input
      newInput.push(e.key)
      newInput.splice(-code.length - 1, input.length - code.length)

      setInput(newInput)

      if (newInput.join('').includes(code.join(''))) {
        action()
        setInput([])
      }
    },
    [input, setInput, code, action],
  )

  React.useEffect(() => {
    if (!document) return // check document is mounted
    document.addEventListener('keyup', onKeyUp)
    return () => {
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [onKeyUp])
}
