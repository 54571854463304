import { useRef } from "react";
import { ToolTip } from '../ToolTip'

type RadarChartPointProps = {
  x: number;
  y: number;
  fill: string;
  label?: string;
  value: string;
}

export function RadarChartPoint(props :RadarChartPointProps) {
  const {
    x,
    y,
    fill,
    label,
    value
  } = props;

  const circle = useRef<SVGCircleElement>(null);

  return (
    <>
      <circle
        fill={ fill }
        stroke="#FFFFFF"
        strokeWidth={ 1 }
        ref={ circle }
        cx={ x }
        cy={ y }
        r={ 4 } />
      <ToolTip
        position="bottom"
        anchor={ circle }
        delay={ 25 }
        paddingBetweenAnchorAndTooltip={ 10 }>
        { label ? <div><strong>{ label }</strong></div> : null }
        { value }
      </ToolTip>
    </>
  );
};
