import { createContext } from "react";

export type BarContextType = {
  registerBarSegment: (barSegmentId: string) => void;
  unregisterBarSegment: (barSegmentId: string) => void;
  getBarXOffset: (barSegmentId: string) => number;
  getBarYOffset: (barSegmentId: string, barIndex: number, value: number) => number;
  numberOfBars: number;
  barDisplayWidth: number;
  barClusterWidth: number;
}

export const BarContext = createContext<BarContextType>({ 
  registerBarSegment: () => null,
  unregisterBarSegment: () => null,
  getBarXOffset: () => 0,
  getBarYOffset: () => 0,
  numberOfBars: 0,
  barDisplayWidth: 0,
  barClusterWidth: 0
});
