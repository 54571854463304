import { SVGProps } from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}
const SvgLogo = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="50px" // TODO: width and height should not be hardcoded
    height="50px" //      ...OR, should match the viewBox
    viewBox="0 0 49 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M7.538 0v7.5H0C0 3.36 3.378 0 7.538 0ZM7.538 8.25H0v7.5h7.538v-7.5ZM7.538 16.5H0V24h7.538v-7.5ZM8.292 0h7.539c0 4.14-3.378 7.5-7.539 7.5V0ZM8.292 8.25h7.539c0 4.14-3.378 7.5-7.539 7.5v-7.5ZM24.123 0v7.5h-7.538c0-4.14 3.378-7.5 7.538-7.5ZM16.585 8.25h7.538v7.5c-4.16 0-7.538-3.36-7.538-7.5ZM24.123 16.5V24h-7.538c0-4.14 3.378-7.5 7.538-7.5ZM24.877 0h7.538c0 4.14-3.378 7.5-7.538 7.5V0ZM24.877 8.25c4.16 0 7.538 3.36 7.538 7.5h-7.538v-7.5ZM24.877 16.5h7.538c0 4.14-3.378 7.5-7.538 7.5v-7.5ZM40.708 0v7.5h-7.539c0-4.14 3.378-7.5 7.539-7.5ZM40.708 8.25h-7.539v7.5h7.539v-7.5ZM33.17 16.5h7.538V24c-4.16 0-7.539-3.36-7.539-7.5ZM41.462 0H49c0 4.14-3.378 7.5-7.538 7.5V0ZM49 12h-7.538v3.75H49V12ZM49 16.5h-7.538V24H49v-7.5Z"
      fill="var(--color-primary-dark)"
    />
  </svg>
)
export default SvgLogo
