import Image from 'next/image'

import { cn } from '@fsg/utils'

import lightProgressGif from '../assets/lightprogress.gif'

type Props = {
  className?: string
}

export function LightProgressLoader({ className }: Props) {
  return <Image className={cn('w-[60px]', className)} src={lightProgressGif} alt="Loading" width={60} height={60} unoptimized={true} />
}
