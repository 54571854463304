import { createContext } from "react";

export type DonutChartCategoryType = {
  label: string;
  id: string;
  color?: string | null;
}

export type DonutChartValueType = {
  category: string;
  value: number;
}


export type DonutChartContextType = {
  chartWidth: number;
  chartHeight: number;
  categories: DonutChartCategoryType[];
}

export const DonutChartContext = createContext<DonutChartContextType>({
  chartWidth: 0,
  chartHeight: 0,
  categories: []
});
