import { useState, useEffect, useRef, ReactNode, RefObject } from 'react'
import ReactDOM from 'react-dom'
import cn from 'classnames'
import { useOverlayContentContext } from '../../hooks'
import moduleStyles from './ToolTip.module.scss'

type ToolTipPosition = 'top' | 'bottom' | 'left' | 'right'

export type ToolTipProps = {
  shown?: boolean
  wrapperClassName?: string
  className?: string
  anchor: Partial<RefObject<HTMLElement | SVGElement>>
  position?: ToolTipPosition
  delay?: number
  paddingBetweenAnchorAndTooltip?: number
  wrapperSize?: number
  children?: ReactNode
}

export function ToolTip(props: ToolTipProps): JSX.Element | null {
  const {
    shown: defaultShown = false,
    wrapperClassName: wrapperClassNameFromProps,
    className,
    anchor,
    position: positionFromProps = 'bottom',
    delay = 500,
    paddingBetweenAnchorAndTooltip = 10,
    wrapperSize = 160,
    children,
  } = props

  const [shown, setShown] = useState(defaultShown)
  const [hiding, setHiding] = useState(false)
  const [anchorBoundingRect, setAnchorBoundingRect] = useState<DOMRect | null>(null)
  const showingTimeout = useRef<NodeJS.Timeout>()

  const { overlayContentRoot, mounted } = useOverlayContentContext()

  useEffect(() => {
    if (!hiding) return
    if (showingTimeout.current) clearTimeout(showingTimeout.current)

    const timeout = setTimeout(() => {
      setHiding(false)
      setShown(false)
    }, 200)

    return () => clearTimeout(timeout)
  }, [hiding])

  useEffect(() => {
    const node = anchor.current
    if (!node) return
    if (showingTimeout.current) clearTimeout(showingTimeout.current)

    const handleAnchorMouseEnter = () => {
      showingTimeout.current = setTimeout(() => {
        const node = anchor.current
        if (!node) return
        setAnchorBoundingRect(node.getBoundingClientRect())
        setShown(true)
      }, delay)
    }

    const handleAnchorMouseLeave = () => setHiding(true)

    node.addEventListener('mouseenter', handleAnchorMouseEnter)
    node.addEventListener('mouseleave', handleAnchorMouseLeave)
    setAnchorBoundingRect(node.getBoundingClientRect())

    return () => {
      node.removeEventListener('mouseenter', handleAnchorMouseEnter)
      node.removeEventListener('mouseleave', handleAnchorMouseLeave)
    }
  }, [anchor, showingTimeout, setAnchorBoundingRect, setShown, setHiding, delay])

  useEffect(() => {
    if (!shown) return

    const handleScrollWhileShown = () => {
      const node = anchor.current
      if (!node) return
      setAnchorBoundingRect(node.getBoundingClientRect())
    }

    document.addEventListener('scroll', handleScrollWhileShown, true)

    return () => document.removeEventListener('scroll', handleScrollWhileShown, true)
  }, [shown, anchor, setAnchorBoundingRect, setHiding])

  if (typeof window !== 'undefined') {
    if (!shown || !anchorBoundingRect) return null

    const {
      top: anchorTop,
      left: anchorLeft,
      width: anchorWidth,
      height: anchorHeight,
    } = anchorBoundingRect

    if (anchorWidth == 0 && anchorHeight == 0) return null

    const viewHeight = window.document.documentElement.clientHeight
    const viewWidth = window.document.documentElement.clientWidth

    let top = anchorTop,
      left = anchorLeft,
      align: ToolTipPosition = 'top',
      position = positionFromProps

    const bottom = top + anchorHeight
    const right = left + anchorWidth

    const WRAPPER_MARGIN = wrapperSize + paddingBetweenAnchorAndTooltip

    if (position.includes('top') && top < WRAPPER_MARGIN && bottom <= viewHeight - WRAPPER_MARGIN) {
      position = position.replace('top', 'bottom') as ToolTipPosition
    } else if (
      position.includes('bottom') &&
      bottom > viewHeight - WRAPPER_MARGIN &&
      top >= WRAPPER_MARGIN
    ) {
      position = position.replace('bottom', 'top') as ToolTipPosition
    }

    if (position.includes('left') && left < WRAPPER_MARGIN) {
      if (right > viewWidth - WRAPPER_MARGIN) {
        position = 'bottom'
      } else {
        position = position.replace('left', 'right') as ToolTipPosition
      }
    } else if (position.includes('right') && right > viewWidth - WRAPPER_MARGIN) {
      if (left < WRAPPER_MARGIN) {
        position = 'bottom'
      } else {
        position = position.replace('right', 'left') as ToolTipPosition
      }
    }

    if (position == 'top') {
      if (left < WRAPPER_MARGIN / 2) {
        position = 'right'
      } else if (right > viewWidth - WRAPPER_MARGIN / 2) {
        position = 'left'
      }
    } else if (position == 'right') {
      if (top < WRAPPER_MARGIN / 2) {
        position = 'bottom'
      } else if (bottom > viewHeight - WRAPPER_MARGIN / 2) {
        position = 'top'
      }
    } else if (position == 'bottom') {
      if (left < WRAPPER_MARGIN / 2 && right < viewWidth - WRAPPER_MARGIN / 2) {
        position = 'right'
      } else if (right > viewWidth - WRAPPER_MARGIN / 2 && left > WRAPPER_MARGIN / 2) {
        position = 'left'
      } else if (bottom > viewHeight - WRAPPER_MARGIN / 2) {
        position = 'bottom'
      }
    } else if (position == 'left') {
      if (top < WRAPPER_MARGIN / 2) {
        position = 'bottom'
      } else if (bottom > viewHeight - WRAPPER_MARGIN / 2) {
        position = 'top'
      }
    }

    if (position == 'top') {
      top -= paddingBetweenAnchorAndTooltip
      left += anchorWidth / 2
      align = 'bottom'
    } else if (position == 'bottom') {
      top += anchorHeight + paddingBetweenAnchorAndTooltip
      left += anchorWidth / 2
      align = 'top'
    } else if (position == 'left') {
      left -= paddingBetweenAnchorAndTooltip
      top += anchorHeight / 2
      align = 'right'
    } else if (position == 'right') {
      left += anchorWidth + paddingBetweenAnchorAndTooltip
      top += anchorHeight / 2
      align = 'left'
    }

    const wrapperClassName = cn(
      moduleStyles['wrapper'],
      wrapperClassNameFromProps,
      moduleStyles[`wrapper-align-${align}`],
    )
    const wrapperStyles = { top, left }

    const nodeClassName = cn(moduleStyles['tool-tip'], className, {
      [moduleStyles['tool-tip--hiding']]: hiding,
    })

    return ReactDOM.createPortal(
      <div className={wrapperClassName} style={wrapperStyles}>
        <div className={nodeClassName}>
          <div className={moduleStyles['tool-tip-inner']}>{children}</div>
        </div>
      </div>,
      overlayContentRoot as HTMLElement,
    )
  } else {
    return null
  }
}
