import { ButtonHTMLAttributes, forwardRef, Ref } from 'react'
import cn from 'classnames/bind'

import moduleStyles from './Button.module.scss'

let cx = cn.bind(moduleStyles)

type ButtonType = 'primary' | 'secondary' | 'ghost' | null
type ButtonSize = 'large' | 'medium' | 'small' | null

export type ButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & {
  ref?: Ref<HTMLButtonElement>
  className?: string | null
  buttonType?: ButtonType
  size?: ButtonSize
  active?: boolean | null
  loading?: boolean | null
  withIcon?: boolean | null
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(props: ButtonProps, _ref) {
  const { className, buttonType, active, withIcon, size, children, loading, ...rest } = props

  const buttonClassName = cx('button', className, {
    'button--primary': buttonType == 'primary',
    'button--secondary': buttonType == 'secondary',
    'button--ghost': buttonType == 'ghost',
    'button--small': size == 'small',
    'button--medium': size == 'medium',
    'button--large': size == 'large',
    'button--active': !!active,
    'button--has-icon': !!withIcon,
    'button--loading': !!loading,
  })

  if (loading) rest.disabled = true

  return (
    <button {...rest} ref={_ref} role="button" aria-disabled={rest.disabled} className={buttonClassName}>
      {/* {loading && (
        <div className={moduleStyles['loading-overlay']}>
          <LoadingSpinner size="small" />
        </div>
      )} */}
      {children}
    </button>
  )
})
