import { useContext, useEffect, useRef } from 'react';
import { ToolTip  } from '../ToolTip'
import { ChartContext, ChartDataSourceContext  } from '../Chart'
import { translateColorToSafeString, formatFriendlyDate, formatDateTimeOnly } from '../../helpers';

type InnerBarProps = {
  id: string,
  displayWidth: number,
  clusterWidth: number,
  start: number,
  end: number,
  value: number,
  filledColor: string,
  unfilledColor: string,
  noUnfilled: boolean,
  tooltip: boolean,
  xOffset: number,
  yOffset: number
}

export function InnerBar(props: InnerBarProps) {
  const {
    id,
    displayWidth,
    clusterWidth,
    start,
    end,
    value,
    filledColor,
    unfilledColor,
    noUnfilled,
    tooltip,
    xOffset,
    yOffset
  } = props;

  const filledRef = useRef<SVGPathElement>(null);

  const { chartWidth, chartHeight, chartPadTop, chartPadLeft, isCanvas, canvasRef, canvasRefreshCounter } = useContext(ChartContext);
  const { tooltipLabel, timeScale } = useContext(ChartDataSourceContext);

  useEffect(() => {
    if (!isCanvas || !canvasRef?.current) return;
    if (!timeScale) return;

    const { min: minDate, max: maxDate } = timeScale;

    const msCenter = start + ((end - start) / 2);
    const centerX = chartWidth * ((msCenter - minDate) / (maxDate - minDate));
    const leftX = centerX - (clusterWidth / 2);

    let filledY = chartPadTop + ((1 - value) * chartHeight);

    const ctx = canvasRef.current.getContext('2d');
    const dpr = window.devicePixelRatio || 1;

    if (!ctx) return;

    if (yOffset) {
      filledY -= (yOffset * chartHeight) * dpr;
    } else if (!yOffset && !noUnfilled) {
      ctx.beginPath();
      ctx.fillStyle = translateColorToSafeString(unfilledColor, canvasRef.current);
      ctx.fillRect(
        (chartPadLeft + leftX + xOffset) * dpr,
        chartPadTop * dpr,
        displayWidth * dpr,
        chartHeight * dpr
      );
      ctx.stroke();
    }

    ctx.beginPath();
    ctx.fillStyle = translateColorToSafeString(filledColor, canvasRef.current);
    ctx.fillRect(
      (chartPadLeft + leftX + xOffset) * dpr,
      filledY * dpr,
      displayWidth * dpr,
      (value * chartHeight) * dpr
    );
    ctx.stroke();
  }, [isCanvas, canvasRef, canvasRefreshCounter, chartWidth, chartHeight, chartPadTop, chartPadLeft, timeScale, start, end, clusterWidth]);

  if (isCanvas) return null;

  if (!timeScale) return null;

  const { min: minDate, max: maxDate } = timeScale;

  const msCenter = start + ((end - start) / 2);
  const centerX = chartWidth * ((msCenter - minDate) / (maxDate - minDate));
  const leftX = centerX - (clusterWidth / 2);

  let filledY = chartPadTop + ((1 - value) * chartHeight);

  if (yOffset) {
    filledY -= yOffset * chartHeight;
  }

  const filledInstructions = `M${chartPadLeft + leftX + xOffset + (displayWidth / 2)},${filledY}
                              h${displayWidth / 2}
                              v${value * chartHeight}
                              h${-1 * displayWidth}
                              v${-1 * (value * chartHeight)}
                              h${displayWidth / 2} z`.replace(/\n\s+/g, ' ');

  const unfilledInstructions = `M${chartPadLeft + leftX + xOffset + (displayWidth / 2)},${chartPadTop}
                                h${displayWidth / 2}
                                v${chartHeight}
                                h${-1 * displayWidth}
                                v${-1 * chartHeight}
                                h${displayWidth / 2} z`.replace(/\n\s+/g, ' ');

  const showDate = +new Date(start) !== +new Date(end);

  return (
    <>
      <defs>
        <clipPath id={id}>
          <rect
            x={chartPadLeft + leftX + xOffset}
            y={chartPadTop}
            width={displayWidth}
            height={chartHeight}
            rx={2} />
        </clipPath>
      </defs>
      {(!yOffset && !noUnfilled) ? (
        <path
          d={unfilledInstructions}
          clipPath={`url(#${id})`}
          fill={unfilledColor} />
      ) : null
      }
      {!!value ? (
        <>
          <path
            d={filledInstructions}
            clipPath={`url(#${id})`}
            ref={filledRef}
            fill={filledColor}
            strokeWidth={2}
            stroke="transparent" />
          {tooltip ? (
            <ToolTip
              position="bottom"
              anchor={filledRef}
              delay={25}>
              <>
                <strong>{tooltipLabel}</strong><br />
                <span>{`${Math.round(value * 100)}% of range`}</span><br />
                <span>{formatFriendlyDate(start)} - {showDate ? formatFriendlyDate(end) : formatDateTimeOnly(end)}</span>
              </>
            </ToolTip>
          ) : null
          }
        </>
      ) : null
      }
    </>
  );
};

