/**
 * Parses a number from a string.
 * @param {string} value the string value to parse
 * @returns {number} the parsed number as either a float point or integer, or NaN if the string contains letters
 */
// REVIEW >> WHy not just use `Number(value)`?
export function parseNumberFromString(value: string) {
  if (value.match(/[aA-zZ]/)) return NaN
  if (value.includes('.')) {
    return parseFloat(value)
  }
  return parseInt(value)
}
