export function parseWeblink(urlObj: URL): { type: string; href: string; handle?: string } {
  const hostname = urlObj.hostname
  const pathParts = urlObj.pathname.split('/').filter((part) => part) // Filter out empty parts, i.e. leading and trailing slashes in the pathnam

  let handle: string | undefined
  if (pathParts.length > 0) {
    handle = pathParts[0]
  }

  if (hostname.includes('instagram.com')) {
    return { type: 'Instagram', href: urlObj.toString(), handle }
  } else if (hostname.includes('facebook.com')) {
    return { type: 'Facebook', href: urlObj.toString(), handle }
  } else if (hostname.includes('linkedin.com')) {
    return { type: 'LinkedIn', href: urlObj.toString(), handle }
  } else if (hostname.includes('twitter.com')) {
    return { type: 'Twitter', href: urlObj.toString(), handle }
  } else {
    return { type: 'Weblink', href: urlObj.toString() }
  }
}
