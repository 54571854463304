import { SVGProps } from 'react'

import { cn } from '@fsg/utils'

export type LoadingSpinnerProps = {
  className?: string
}

// * Mostly Dan's component, pruned down...  Will refactor before implementing
export function ButtonV2LoadingSpinner({ className, ...props }: LoadingSpinnerProps) {
  return (
    <div
      // REVIEW >> test this... "arbitrary value for `duration` in tailwind-animate does not work properly"
      style={{ animationDuration: '2500ms' }}
      className={cn(
        'flex items-center justify-center',
        'ease-[cubic-bezier(0.5, 0, 0.5, 1)] animate-[fsg-spin] repeat-infinite',
        // 'animate-spin', // REVIEW >> check this
        className,
      )}
    >
      <Spinner title="Loading" />
    </div>
  )
}

// REVIEW >> address this...
// ? Why is this here?
// This is different from the Spinner in the design system icons
// It looks the same but width and height are responsive so it will scale with the LoadingSpinner's dynamic sizes, and the svg components are different, using paths rather than circles (it was made in Figma and copied over)
// furthermore, it has set colors for the paths whereas the design system Spinner uses currentColor

interface SVGRProps {
  title?: string
}
const Spinner = ({ title, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="spinner" {...props}>
    {title ? <title id="spinner">{title}</title> : null}
    <path
      d="M12.1819 8.16529C10.0276 8.16529 8.28112 6.41884 8.28112 4.26447C8.28112 2.11011 10.0276 0.363647 12.1819 0.363647C14.3363 0.363647 16.0828 2.11011 16.0828 4.26447C16.0828 6.41884 14.3363 8.16529 12.1819 8.16529Z"
      fill="#ced1d7"
    />
    <path
      d="M12.1819 23.7686C10.0276 23.7686 8.28112 22.0221 8.28112 19.8678C8.28112 17.7134 10.0276 15.9669 12.1819 15.9669C14.3363 15.9669 16.0828 17.7134 16.0828 19.8678C16.0828 22.0221 14.3363 23.7686 12.1819 23.7686Z"
      fill="#ced1d7"
    />
    <path
      d="M19.9836 14.9917C18.3678 14.9917 17.0579 13.6819 17.0579 12.0661C17.0579 10.4503 18.3678 9.1405 19.9836 9.1405C21.5993 9.1405 22.9092 10.4503 22.9092 12.0661C22.9092 13.6819 21.5993 14.9917 19.9836 14.9917Z"
      fill="#000"
      stroke="#000"
      strokeWidth="1.68182"
    />
    <path
      d="M4.38029 14.9917C2.76452 14.9917 1.45467 13.6819 1.45467 12.0661C1.45467 10.4503 2.76452 9.1405 4.38029 9.1405C5.99606 9.1405 7.30591 10.4503 7.30591 12.0661C7.30591 13.6819 5.99606 14.9917 4.38029 14.9917Z"
      fill="#000"
      stroke="#000"
      strokeWidth="1.68182"
    />
  </svg>
)
