import { useRef } from 'react'
// import { ToolTip } from '../ToolTip'

export type DurationTextProps = {
  minutes?: boolean
  hours?: boolean
  days?: boolean
  months?: boolean
  years?: boolean
  className?: string
  children: string
  tooltip?: boolean
}

export function DurationText(props: DurationTextProps) {
  const {
    minutes = true,
    hours = true,
    days = true,
    months = false,
    years = false,
    tooltip = false,
    className,
    children,
  } = props
  const ref = useRef(null)

  if (!(typeof children == 'string')) throw new Error('DurationText requires a single string child')

  const string = children as string
  let number: number,
    not_a_number = false

  if (string.includes('.')) {
    number = parseFloat(string)
  } else {
    number = parseInt(string)
  }

  if (isNaN(number) || number != (string as unknown as number)) not_a_number = true

  let display_number: string
  let display_format: string

  if (!not_a_number) {
    const parts = []
    const format = []

    const negative = number < 0
    if (negative) number = -number
    const mm = (number / 60) % 60,
      hh = (number / (60 * 60)) % 24,
      dd = (number / (60 * 60 * 24)) % 30,
      MM = (number / (60 * 60 * 24 * 30)) % 12,
      yy = number / (60 * 60 * 24 * 30 * 12)

    if (years) {
      parts.push(`${Math.floor(yy).toString().padStart(2, '0')}`)
      format.push('YY')
    }
    if (months) {
      parts.push(`${Math.floor(MM).toString().padStart(2, '0')}`)
      format.push('MM')
    }
    if (days) {
      parts.push(`${Math.floor(dd).toString().padStart(2, '0')}`)
      format.push('DD')
    }
    if (hours) {
      parts.push(`${Math.floor(hh).toString().padStart(2, '0')}`)
      format.push('HH')
    }
    if (minutes) {
      parts.push(`${Math.floor(mm).toString().padStart(2, '0')}`)
      format.push('mm')
    }

    display_number = (negative ? '-' : '') + parts.join(':')
    display_format = format.join(':')
  } else {
    display_number = ['null', 'undefined'].includes(string) ? '-' : string
    display_format = ['null', 'undefined'].includes(string) ? 'No Data' : string
  }

  return (
    <>
      <div className={className} ref={ref}>
        {display_number}
      </div>
      {/* {tooltip ? <ToolTip anchor={ref}>{display_format}</ToolTip> : null} */}
    </>
  )
}
