import { forwardRef } from 'react'
import cn from 'classnames'
import { CardButton, CardButtonProps } from '../CardButton'
import { KPICard, KPICardProps } from '../KPICard'
import { ExternalLink } from '@fsg/icons'
import moduleStyles from './KPICardButton.module.scss'

export type KPICardButtonProps = Omit<KPICardProps, 'component'> & CardButtonProps

export const KPICardButton = forwardRef<HTMLDivElement, KPICardButtonProps>(function KPICardButton(
  props: KPICardButtonProps,
  _ref,
) {
  const { className, label, ...rest } = props

  const cardClassName = cn(moduleStyles['m--kpi-card-button'], className, {
    [moduleStyles['m--kpi-card-button--disabled']]: rest.disabled,
  })

  return (
    <KPICard
      ref={_ref}
      {...rest}
      labelClassName={moduleStyles['m--label']}
      label={
        <>
          <span className={moduleStyles['m--label-inner']}>{label}</span>
          <ExternalLink width={14} height={14} />
        </>
      }
      component={CardButton}
      className={cardClassName}
    />
  )
})
