import { pattern } from './formValidation'

export function formatPhoneNumber(input: string) {
  const isIntl = input.startsWith('+');
  const cleaned = input.replace(/\D/g, '') // removes any non-digit characters, leaving only numbers
  const isValid = input.match(pattern.phone.NA) || cleaned.match(pattern.phone.INTL) // check if number is valid

  if (!isValid) {
    return { input, formatted: undefined, isValid: false }
  }

  // match[0] represents the full match
  // match[1] represents the first group that matches 0-3 digits, the international code
  // match[2] represents the second group that matches 0-3 digits, the area code
  // match[3] represents a third group that matches 0-4 digits, the number

   let match
   if (isIntl) {
     match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/) // Group for international format
   } else {
     match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/) // Group for NA format
   }

   

  if (match) {
    const intlCode = match[1] ? `+${match[1]} ` : ''
    const areaCode = match[2] ? `(${match[2]})` : ''
    const number = [match[3], match[4]].filter(Boolean).join('-')
    // filter(Boolean) is used to remove any match groups that returned falsy values
    // only case is for strings that don't include the international code

    return {
      input,
      formatted: [intlCode, areaCode, number].filter(Boolean).join(' '),
      isValid: true,
    }
  }
}
