'use client'
import { createContext, RefObject } from 'react';

export type YScale = {
  min: number;
  max: number;
  valueLabels?: Map<number, string>;
}

export type XScale = {
  min: number;
  max: number;
}

export type ChartContextType = {
  chartWidth: number;
  chartHeight: number;
  chartPadTop: number;
  chartPadLeft: number;

  canvasRef?: RefObject<HTMLCanvasElement>,
  isCanvas: boolean,
  canvasRefreshCounter: number,

  yAxesPositioning: {
    [axisId: string]: {
      left: number,
      width: number
    }
  };

  xAxesPositioning: {
    [axisId: string]: {
      top: number,
      height: number
    }
  };

  numericScale: {
    min: number;
    max: number
  };

  registerYScale: (yScale: YScale) => void;
  unregisterYScale: (yScale: YScale) => void;

  registerYAxis: (yAxisID: string, usingNumericScale: boolean, longestExpectedValueLabelLength: number) => void;
  unregisterYAxis: (yAxisID: string) => void;

  registerXAxis: (xAxisID: string) => void;
  unregisterXAxis: (xAxisID: string) => void;
}

export const ChartContext = createContext<ChartContextType>({
  chartWidth: 0,
  chartHeight: 0,
  chartPadTop: 0,
  chartPadLeft: 0,

  isCanvas: false,
  canvasRefreshCounter: 0,

  yAxesPositioning: {},
  xAxesPositioning: {},

  numericScale: {
    min: -Infinity,
    max: Infinity
  },

  registerYScale: () => null,
  unregisterYScale: () => null,

  registerYAxis: () => null,
  unregisterYAxis: () => null,

  registerXAxis: () => null,
  unregisterXAxis: () => null
});
